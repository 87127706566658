import axios from '@/config/httpConfig'
import qs from  'qs'
//获取商户认证详情
export const statistics=(params)=> {
	  return axios('get','Data/shop/statistics', params)
}
//销售商品榜单
export const goodsSaleData=(params)=> {
	  return axios('get','/Data/shop/goodsSaleData', params)
}
//数据看榜概览
//合伙人详情-用户信息
export const viewUserInfo=(params)=> {
    return axios('get','/Account/shop/data/viewUserInfo', params)
}
//合伙人详情-数据看榜概览
export const viewLevel=(params)=> {
    return axios('get','/Account/shop/data/view', params)
}
//合伙人详情-数据看榜概览2
export const myView=(params)=> {
    return axios('get','/Account/shop/data/myView', params)
}
//合伙人详情-门店信息
export const shopCenter=(params)=> {
    return axios('get','/Account/shop/data/shopCenter', params)
}

//折线图-门店信息
export const tradeData=(params)=> {
    return axios('get','/Data/shop/tradeData', params)
}