import axios from '@/config/httpConfig'
import qs from  'qs'
//登陆
export const logins=(data)=> {
    return axios('post','/Account/platform/platformAccountLogin', data);
}
//获取平台登录者账号信息
export const userBasicsInfo=(params)=> {
	  return axios('get','/Account/platform/getPlatformAccountInfo', params)
}
//修改平台登录者账号信息
export const upUserBasicsINfo=(data)=> {
    return axios('post','/Account/platform/revampPlatformAccountInfo', data);
}
//刷新令牌
export const platRefreshToken=(data)=> {
    return axios('post','/TokenCheck/platform/refreshToken', data);
}
//协议
export const getProtocol=(params)=> {
	  return axios('get','Data/shop/getProtocol', params)
}
//退出登录
export const quitLoginPlat=(params)=> {
	  return axios('get','Account/platform/quit', params)
}
export const getTestAes=(data)=> {
	return axios('post','Account/shop/getTestAes', data);
}