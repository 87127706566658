<template>
 <div id="app" >
     <transition name="fade" mode="out-in">
         <router-view v-if="isRouterAlive"></router-view>
     </transition>
 </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            isRouterAlive: true
        }
    },
    provide() {
        return {
            reload: this.reload
        }
    },

	watch: {
		// "$route": {
		// 	handler(val) {
		// 		console.log(val.path,"5555")
		// 		if(val.path =='/login' || val.path =='/merchantlogin'){
		// 			this.$store.commit('LOGIN_OUT');
		// 			localStorage.removeItem('dataset');
		// 			localStorage.removeItem('head_portrait');
		// 			localStorage.removeItem('terminal');
		// 			localStorage.removeItem('shop_name');
		// 			localStorage.removeItem('refreshToken')
		// 			localStorage.removeItem('token');
		// 			if (location.href.indexOf("&type") == -1) {
		// 				location.href = location.href + "&type";
		// 				window.location.reload();
		// 			}
		// 		}
		// 	},
		// 	immediate: true
		// }
	},
    methods: {
        reload() {
            this.isRouterAlive = false
            this.$nextTick(() => (this.isRouterAlive = true))
        }
    },
    mounted() {
	
	},
	destroyed(){
		this.$store.commit('LOGIN_OUT');
		localStorage.removeItem('dataset');
		localStorage.removeItem('head_portrait');
		localStorage.removeItem('terminal');
		localStorage.removeItem('shop_name');
		localStorage.removeItem('refreshToken')
		localStorage.removeItem('token');
	}
}
</script>

<style lang="scss">
@import "./assets/css/main.scss";
@import "./assets/css/utilCss.scss";
@import "./assets/css/loading.css";
</style>
