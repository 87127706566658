import axios from 'axios'
import store from '@/store/index.js'
import baseURL from './baseUrl'
import Tools from '@/utils/tools'
import router from '@/router'
let Base64 = require('js-base64').Base64
const sha256 = require('js-sha256').sha256
import md5 from 'js-md5';

import { platRefreshToken } from "@/api/plat/platlogin";
import { shopRefreshToken } from "@/api/merchant/login";
import { channelRefreshToken } from "@/api/channel/login";
import { Message } from 'element-ui'

// const http = {}
var instance = axios.create({
	timeout: 40000,
	baseURL,
	validateStatus(status) {
		switch (status) {
			case 400:
				Message.error('请求出错')
				break
			case 401:
				Message.warning({
					message: '授权失败，请重新登录'
				})
				store.commit('LOGIN_OUT')
				setTimeout(() => {
					window.location.reload()
				}, 1000)
				return
			case 403:
				Message.warning({
					message: '拒绝访问'
				})
				break
			case 404:
				Message.warning({
					message: '请求错误,未找到该资源'
				})
				break
			case 500:
				Message.warning({
					message: '服务端错误'
				})
				break
		}
		return status >= 200 && status < 300
	}
})

// 添加请求拦截器
instance.interceptors.request.use(config => {
	// 请求头添加token
	let token = store.state.UserToken;
	if (token) {
		//let dd = config.method === "get" ? config.params : config.data;// 请求体
		let dd = config.method === "get" ? config.params : config.isUpload ? {} : config.data;// 请求体


		if (config.isUpload) {//文件格式特殊处理
			config.data.forEach((value, key) => {
				if (key != 'file') { dd[key] = value }
			});
		}
		//添加通用参数
		config.headers.token = token;
		config.headers['Content-Type'] = config.isUpload ? 'application/x-www-form-urlencoded' : 'application/json;';
		// 加密传参
		dd.timestamp = (new Date().valueOf() / 1000).toFixed(0) * 1;
		const data = JSON.parse(JSON.stringify(dd));
		data.dynamic = localStorage.getItem('dynamic');
		const ordered = {};
		delete data["sign"];
		Object.keys(data).sort().forEach(function (k) {
			ordered[k] = data[k];
		});

		let string = '';
		for (const key in ordered) {
			string += `${key}=${ordered[key]}&`;
		}
		string = string.substring(0, string.length - 1);
		dd.sign = md5(string);
		if (config.method.toLocaleUpperCase() == 'GET') {
			config.params = dd;
		} else {
			if (config.isUpload) {
				config.data.append("sign", dd.sign)
				config.data.append("timestamp", dd.timestamp)
			} else {
				config.data = dd;
			}
		}
	}
	return config;
},
	(error) => {
		return Promise.reject(error)
	}
)

// 响应拦截器即异常处理
instance.interceptors.response.use(async response => {
	if (response.data.return_code == 'SUCCESS') {
		return response.data;
	} else if (response.data.return_code == 'TOKEN_IS_INVALID') {//token失效  TOKEN_HAS_EXPIRED 登录过期需要重新登陆  =>  刷新token
		response.data = await doRequest(response.config);
	} else if (response.data.return_code == 'TOKEN_HAS_EXPIRED' || response.data.return_code == 'ILLEGAL_REQUEST_PLEASE_CHECK_THE_PARAMETERS') {
		Message.error({
			message: '登陆信息失效，请重新登陆'
		});
		if (localStorage.getItem('terminal') == 'platform') {
			store.commit('LOGIN_OUT');
			localStorage.removeItem('dataset');
			localStorage.removeItem('terminal');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('head_portrait');
			localStorage.removeItem('shop_name');
			localStorage.removeItem('token');
			/* 防止切换角色时addRoutes重复添加路由导致出现警告 */
			setTimeout(() => {
				//window.location.reload()
				router.push('/shoplogin');
			}, 3000);

		} else if (localStorage.getItem('terminal') == 'shop') {
			store.commit('LOGIN_OUT');
			localStorage.removeItem('dataset');
			localStorage.removeItem('terminal');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('head_portrait');
			localStorage.removeItem('shop_name');
			localStorage.removeItem('token');
			/* 防止切换角色时addRoutes重复添加路由导致出现警告 */
			setTimeout(() => {
				localStorage.setItem("activeName", 'first')
				router.push('/shoplogin');
			}, 3000);

		} else if (localStorage.getItem('terminal') == 'channel') {
			store.commit('LOGIN_OUT');
			localStorage.removeItem('dataset');
			localStorage.removeItem('terminal');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('head_portrait');
			localStorage.removeItem('shop_name');
			localStorage.removeItem('token');
			/* 防止切换角色时addRoutes重复添加路由导致出现警告 */
			setTimeout(() => {
				localStorage.setItem("activeName", 'second')
				router.push('/shoplogin');
			}, 3000);
		}
	}
	return response.data;
},
	error => {
		console.log(JSON.stringify(error.message))
		//Message.error(JSON.stringify(error.message))
	}
)
let http = (type, url, parmas, option = false) => {
	return new Promise((resolve, reject) => {
		let dataC = option ? parmas : Tools.filterParams(parmas);
		let requestObj = {
			method: type.toLowerCase(),
			url,
			isUpload: option
		};
		switch (type.toLowerCase()) {
			case 'post':
				requestObj.data = dataC
				break;
			case 'get':
				requestObj.params = dataC
				break;
		}
		instance(requestObj).then(response => {
			if (response.return_code == "SUCCESS" || response.return_code == "TOKEN_IS_INVALID" || response.data.return_code == 'TOKEN_HAS_EXPIRED' || response.data.return_code == 'ILLEGAL_REQUEST_PLEASE_CHECK_THE_PARAMETERS') {
				resolve(response)
			} else {
				Message.error(response.return_msg)
				//console.log("4444",response.return_msg)
				//reject(response)
			}

		}).catch(err => {
			console.log(err, "5555555555")
			//Message.error(err.return_msg)
			//reject(err)
			//console.log(e)
		})
	})

};
//刷新token并继续之前请求
async function doRequest(config) {
	let resold, fn;
	if (localStorage.getItem('terminal') == 'platform') {
		fn = platRefreshToken;
	} else if (localStorage.getItem('terminal') == 'shop') {
		fn = shopRefreshToken;
	} else if (localStorage.getItem('terminal') == 'channel') {
		fn = channelRefreshToken;
	}else {
		this.$router.push('/login')
	}
	const res = await fn({ refreshToken: localStorage.getItem('refreshToken') });
	localStorage.setItem('dynamic', res.data.dynamic);
	localStorage.setItem('refreshToken', res.data.refreshToken);
	localStorage.setItem('head_portrait', res.data.head_portrait);
	localStorage.setItem('shop_name', res.data.shop_name);
	localStorage.setItem('terminal', res.data.terminal);
	store.commit('LOGIN_IN', res.data.token);
	if (config.method == 'get') {
		resold = await instance.get(config.url, { params: config.params });
	} else {
		let aa = JSON.parse(config.data);
		resold = await instance.post(config.url, aa);
	}
	return resold;
}




export default http
