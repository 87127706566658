/**
 * 工具类
 */
import Vue from 'vue'
import CryptoJS from 'crypto-js'
let keyStr = "038801a4931c8ec59c8e6c562cd946f0"
let ivStr=''
export default {
	// // 加密
	// encrypt(word){ 
	// 	keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
	// 	var key  = CryptoJS.enc.Utf8.parse(keyStr);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
	// 	var srcs = CryptoJS.enc.Utf8.parse(word);
	// 	var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
	// 	return encrypted.toString();
	// },
	// // 解密
	// decrypt(word){  
	// 	keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
	// 	var key  = CryptoJS.enc.Utf8.parse(keyStr);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
	// 	var decrypt = CryptoJS.AES.decrypt(word, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
	// 	return CryptoJS.enc.Utf8.stringify(decrypt).toString();
	// },
	// 加密
	encrypt(word) {
		keyStr = keyStr ? keyStr : "absoietlj32fai12";
		ivStr = ivStr ? ivStr : "absoietlj32fai12";
		let key = CryptoJS.enc.Utf8.parse(keyStr);
		let iv = CryptoJS.enc.Utf8.parse(ivStr);
		let srcs = CryptoJS.enc.Utf8.parse(word);

		let encrypted = CryptoJS.AES.encrypt(srcs, key, {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.ZeroPadding
		});
		return encrypted.toString();
	},
	// 解密
	decrypt(word) {
		keyStr = keyStr ? keyStr : "absoietlj32fai12";
		ivStr = ivStr ? ivStr : "absoietlj32fai12";
		var key = CryptoJS.enc.Utf8.parse(keyStr);
		let iv = CryptoJS.enc.Utf8.parse(ivStr);

		var decrypt = CryptoJS.AES.decrypt(word, key, {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.ZeroPadding
		});
		return decrypt.toString(CryptoJS.enc.Utf8);
	},
	bin2Hex(str) {
		var re = /[\u4E00-\u9FA5]/;
		var ar = [];
		for (var i = 0; i < str.length; i++) {
			var a = '';
			if (re.test(str.charAt(i))) { // 中文
				a = encodeURI(str.charAt(i)).replace(/%/g, '');
			} else {
				a = str.charCodeAt(i).toString(16);
			}
			ar.push(a);
		}
		str = ar.join("");
		return str;
	}

};
