<template>
	<div class="uploads">
		<el-dialog title="图片资源管理器 " :visible.sync="showOrHide" width="930px" class="good_blos" @close="closeImg">
			<div class="up_top">
				<div class="top_left">
					<!-- <el-button type="primary" size="small" @click="toUploads">上传图片</el-button> -->
					<el-upload :disabled="upImg"  action="javascript:;" :data="paramData"
						:headers="{ token: tokens }" name="file" class="upload-demo" :show-file-list="false"
						:on-success="handleSuccess" multiple :file-list="imgFileList" :before-upload="OnChange">
						<el-button  size="small" type="primary">点击上传</el-button>
					</el-upload>
					<el-button size="small" v-if="level=='1'" @click="createGroup">{{groupNames}}</el-button>
				</div>
				<div class="top_right">
					<label>排序规则</label>
					<el-select v-model="search.sort1" placeholder="请选择" class="el_select" clearable>
						<el-option v-for="item in sortArr" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="search.sort2" placeholder="请选择" class="el_select" @change="nameChange" clearable>
						<el-option v-for="item in nameArr" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
					</el-select>
					<el-input placeholder="请输入图片名称" v-model="search.name" class="input-with-select" clearable>
						<el-button slot="append" icon="el-icon-search" @click="searchImgs">搜索</el-button>
					</el-input>
				</div>
			</div>
			<div class="up_bot">
				<div class="bot_left">
					<el-tree :data="cats" :props="defaultProps" node-key="oss_resource_classify_id" 
						:expand-on-click-node="false" @node-click="handleNodeClick">
						<span class="custom-tree-node" slot-scope="{ node, data }" @mouseover="mouseover(data)" @mouseleave="mouseout(data)">
							<span style="margin-right: 10px;">{{ node.label }}</span>
							<!-- <span v-if="data.oss_resource_classify_id != ''"    class="node-none"> -->
							 <div v-show="data.dropdownShow" @click.stop="()=>{}" >
								<el-dropdown trigger="click" size="small"  class="blo_cats" @click.stop="">
									<span class="el-dropdown-link">
										<i class="el-icon-more" style="color: #2589ff"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item @click.native="editGroup(data)"><i class="el-icon-edit"
												style="color: #409eff"></i>编辑分组</el-dropdown-item>
										<!-- 判断是否能删除-system为Y不能删除 -->
										<el-dropdown-item @click.native="delGroup(data)"
											v-if="data.oss_resource_classify_id != '' && data.system != 'Y'"><i
												class="el-icon-delete" style="color: #409eff"></i>删除分组
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
								 </div>
							<!-- </span> -->
						</span>
					</el-tree>

					<!-- <div v-for="(item, index) in cats" :key="index" class="left_blo"
						:class="isActive == index && 'actives'" @click="changeCat(index)">
						<div>
							{{ item.classify_name }}
						</div>

						<div class="blo_tool" @click.stop="">
							<el-dropdown trigger="click" size="small" class="blo_cats" @click.stop="">
								<span class="el-dropdown-link">
									<i class="el-icon-more" style="color: #2589ff"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item @click.native="editGroup(index)"><i class="el-icon-edit"
											style="color: #409eff"></i>编辑分组</el-dropdown-item>
									 判断是否能删除-system为Y不能删除 -->
					<!-- <el-dropdown-item @click.native="delGroup(index)"
										v-if="index != 0 && item.system != 'Y'"><i class="el-icon-delete"
											style="color: #409eff"></i>删除分组</el-dropdown-item> -->
					<!-- </el-dropdown-menu>
							</el-dropdown>
						</div>
					</div> -->
				</div>
				<div class="bot_right">
					<div class="right_top">
						<div v-for="(img, index) in imgList" :key="index" class="top_blo">
							<div class="blo_div" v-if="img.checked" @click="chooseImg(index)">
								<img src="../../assets/img/uploads/chooseed.png" />
							</div>
							<img :src="img.resource_name" class="blo_img" @click="chooseImg(index)" />
							<div class="blo_text" :class="img.checked && 'shadow_inp'">
								<el-input v-model="img.image_name" :disabled="!img.disabled" :focus="img.disabled"
									@blur="inputBlur(index)" :title="img.image_name"></el-input>
							</div>
							<div class="blo_tool" @click.stop="">
								<el-dropdown trigger="click" size="small" class="blo_cats">
									<span class="el-dropdown-link">
										<i class="el-icon-more" style="color: #fff"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item @click.native="editImg(index)"><i class="el-icon-edit"
												style="color: #409eff"></i>编辑名称</el-dropdown-item>
										<!-- <el-dropdown-item @click.native="delImg(index)"><i class="el-icon-delete"
												style="color: #409eff"></i>删除图片</el-dropdown-item> -->
										<el-dropdown-item @click.native="moveImg(index)"><i class="el-icon-wallet"
												style="color: #409eff"></i>移动分组</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</div>
					<div class="pagination-container cus_page">
						<el-pagination background @size-change="handleSizeChange" hide-on-single-page @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[20, 40, 60, 80]" :page-size="pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="okImg" size="small">确 定</el-button>
				<el-button @click="closeImg" size="small">取 消</el-button>
			</span>
		</el-dialog>
		<!-- 新建分组 -->
		<div class="add_groups">
			<el-dialog :title="groupNames" :visible.sync="isAdd" width="256px" class="good_blos" @close="addClose">
				<el-input type="text" placeholder="请输入分组名称" v-model="classify_name" maxlength="10" show-word-limit>
				</el-input>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" size="small" @click="addSubmit">确 定</el-button>
					<el-button @click="addClose" size="small">取 消</el-button>
				</span>
			</el-dialog>
		</div>
		<!-- 移动图片分组 -->
		<div class="move_imgs">
			<el-dialog title="移动分组" :visible="moveShow" width="256px" class="imgs_blos" @close="moveClose" >
				<el-cascader size="small" v-model="moveGroups.ossResourceClassifyId" :options="originCats" placeholder="请选择要移动的分组" 
					:props="{ expandTrigger: 'hover',value:'oss_resource_classify_id',label:'classify_name',children:'list'}">
				</el-cascader>
				
				<!-- <el-select v-model="moveGroups.ossResourceClassifyId" placeholder="请选择要移动的分组" class="el_select">
					<el-option v-for="item in originCats" :key="item.oss_resource_classify_id"
						:label="item.classify_name" :value="item.oss_resource_classify_id">
					</el-option>
				</el-select> -->
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" size="small" @click="moveSubmit">确 定</el-button>
					<el-button @click="moveClose" size="small">取 消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import baseURL from "@/config/baseUrl";
	import {
		getClassify,
		classifyImagesList,
		delClassify,
		saveClassify,
		fileUploading,
		imagesNameUp,
		classifyUp,
		imagesNameDel,
	} from "@/api/imagesBlo";
	export default {
		props: {
			single: Boolean, //true-单张上传；false-多张上传
		},
		data() {
			return {
				baseUrl: baseURL, //统一地址
				showOrHide: true,
				paramData: {
					//'ossResourceClassifyId':''
				}, //上传参数
				tokens: localStorage.getItem("token"),
				imgFileList: [], //上传图片
				groupNames: '新建分组',
				isAdd: false, //是否显示新建分组
				isActive: 0, //默认选中
				originCats: [],
				cats: [],
				defaultProps: {
					children: 'list',
					label: 'classify_name'
				},
				search: {
					sort1: "",
					sort2: 1,
					name: "",
				}, //搜索条件
				sortArr: [{
						name: "上传时间正序",
						value: 1,
					},
					{
						name: "上传时间倒序",
						value: 2,
					},
					{
						name: "名称正序",
						value: 3,
					},
					{
						name: "名称倒序",
						value: 4,
					},
				],
				nameArr: [{
						name: "图片名称",
						value: 1,
					},
					{
						name: "分组名称",
						value: 2,
					},
				],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 20, //每页的数据
				imgList: [],
				curIndex: 0, //当前分组
				defaultCatId: "", //当前分组id
				moveShow: false, //是否展示移动分组
				moveGroups: {}, //移动分组参数
				pid: 0,
				groupName: {
					name: '',
					id: ''
				}, //分组信息
				classify_name: '',
				oss_resource_classify_id: '',
				level:'1',
				clickNode:{},
				upImg:false
			};
		},
		mounted() {
			// 获取分组
			this.getCats();
		},
		methods: {
			//切换筛选时-清空分类id
			nameChange() {
				this.defaultCatId = "";
				//清除 筛选名称
				this.search.name = "";
			},
			getCats(type) {
				let search = {
					...this.search
				};
				// 过滤空参数
				search.classifyName = search.name;
				search.page = 1;
				search.perPage = 10000;
				if (search.sort1 == 1) {
					search.createdOrderBy = "asc";
				} else if (search.sort1 == 2) {
					search.createdOrderBy = "desc";
				} else if (search.sort1 == 3) {
					search.resourceNameOrderBy = "asc";
				} else if (search.sort1 == 4) {
					search.resourceNameOrderBy = "desc";
				}
				search = this.tools.filterParams(search);
				// console.log(search, 454545);
				getClassify(search)
					.then((res) => {
						// console.log("cats", res);
						//if (this.cats.length > 0) {
						if (type == 1) {
							this.cats = [];
						}
						// this.cats = this.cats.concat(res.data.list);
						this.cats = res.data;
						let pp = {
							classify_name: "全部",
							oss_resource_classify_id: "",
							pid: ''
						};
						this.cats.unshift(pp);
						//console.log(this.cats, "this.cats")
						this.isActive = 0;
						this.defaultCatId =
							this.cats[this.isActive].oss_resource_classify_id || "";
						this.getCatImgs();
						//}
					})
					.catch((err) => {
						this.$message.error(err.return_msg);
					});
			},
			mouseover (data) { // 移入
			      this.$set(data, 'dropdownShow', true)
			    },
			    mouseout (data) { // 移除
			      this.$set(data, 'dropdownShow', false)
			    },
			//点击分类
			handleNodeClick(data) {
				this.clickNode = data;
				// console.log(this.clickNode,"this.clickNode")
				if (data.pid == 0) {
					this.level='1';
					this.upImg=true;
					this.groupNames = '添加子分组';
					this.classify_name = data.classify_name;
					this.pid = data.oss_resource_classify_id;
				}else{
					this.upImg=false;
					this.level='';
				}
				
				if (data.oss_resource_classify_id == '') {
					this.upImg=false;
					this.level='1';
					this.groupNames = '新建分组';
					this.classify_name = data.classify_name;
				}
				//this.getCatImgs();
				//this.isActive = index;
				if (data.oss_resource_classify_id != '') {
					//做加密操作
					let dd = {
						ossResourceClassifyId: data.oss_resource_classify_id,
					};
					this.paramData = dd;
					// console.log(this.paramData, 新增商品11)
				} else {
					this.paramData = {};
				}
				//this.defaultCatId = this.cats[index].oss_resource_classify_id;
				this.search.name = "";
				// 掉接口
				this.getCatImgs();

			},
			// 获取分组下的图片
			getCatImgs() {
				let search = {
					...this.search
				};
				// search.ossResourceClassifyId = this.defaultCatId;
				search.ossResourceClassifyId = this.clickNode.oss_resource_classify_id
				//如果是分组查询-不需要传递该参数
				// console.log(this.search,新增商品11)
				if (search.sort2 == 1) {
					search.resourceName = search.name;
				} else {
					delete search.resourceName;
				}
				delete search.name;
				search.page = this.currentPage;
				search.perPage = this.pagesize;
				// 1,2,3,4
				if (search.sort1 == 1) {
					search.createdOrderBy = "asc";
				} else if (search.sort1 == 2) {
					search.createdOrderBy = "desc";
				} else if (search.sort1 == 3) {
					search.resourceNameOrderBy = "asc";
				} else if (search.sort1 == 4) {
					search.resourceNameOrderBy = "desc";
				}
				// 过滤空参数
				search = this.tools.filterParams(search);
				classifyImagesList(search)
					.then((res) => {
						// console.log("img", res);
						this.total = res.data.count;
						this.imgList = res.data.list;
					})
					.catch((err) => {
						this.$message.error(err.return_msg);
					});
			},

			// 点击图片移动分组
			moveImg(index) {
				// 展示分组弹出层
				this.moveGroups.imgIndex = index;
				this.moveGroups.ossResourceId = this.imgList[index].oss_resource_id;
				this.moveShow = !this.moveShow;
				if (this.moveShow) {
					this.originCats = [...this.cats];
					//console.log(this.originCats,"this.originCats")
					this.originCats.splice(0, 1);
				}
			},
			// 删除图片
			delImg(index) {
				imagesNameDel({
						ossResourceId: this.imgList[index].oss_resource_id
					})
					.then((res) => {
						// console.log("delImg", res);
						this.imgList.splice(index, 1);
					})
					.catch((err) => {
						this.$message.error(err.return_msg);
					});
			},
			// 修改图片名称
			editImg(index) {
				let imgs = [...this.imgList];
				imgs[index].disabled = !imgs[index].disabled;
				this.imgList = [...imgs];
			},
			// 失去焦点-保存图片
			inputBlur(index) {
				let imgs = [...this.imgList];
				if (imgs[index].disabled) {
					imagesNameUp({
							ossResourceId: imgs[index].oss_resource_id,
							newName: imgs[index].image_name + imgs[index].postfix,
						})
						.then((res) => {
							imgs[index].disabled = false;
							this.imgList = [...imgs];
						})
						.catch((err) => {
							if (err.return_code == "SUCCESS") {
								imgs[index].disabled = false;
								this.imgList = [...imgs];
							}
						});
				}
			},
			// 选中图片
			chooseImg(index) {
				// console.log(index);
				// 判断是单选还是多选
				let list = [...this.imgList];
				if (this.single) {
					//单选
					let check = !list[index].checked;
					list.forEach((res) => {
						res.checked = false;
					});
					list[index].checked = check;
				} else {
					list[index].checked = !list[index].checked;
					// list.forEach((res) => {
					//   res.checked = !res.checked;
					// });
				}
				this.imgList = [...list];
			},
			//商品图上传
			uploadLimitImage(params) {
				// console.log(params,新增商品)
				let newList;
				if (
					!(
						params.file.type === "image/png" ||
						params.file.type === "image/gif" ||
						params.file.type === "image/jpg" ||
						params.file.type === "image/jpeg"
					)
				) {
					newList = [];
					return;
				} else {
					this.getImagePost(params.file);
				}
			},
			OnChange (file) {
				console.log("file",file.type)
				const isLt5M = file.size / 1024 / 1024 < 5;
				const isJPG = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'].includes(file.type);
				if (!isLt5M) {
					this.$message.error("上传图片大小不能超过 5MB!");
					return false
				}
				if(!isJPG){
					this.$message.error("请上传正确的文件格式!");
					return false
				}
				if(isLt5M && isJPG){
					// this.imgFileList.push(File);
					const fd = new FormData();
					fd.append('file', file)//当前为
					console.log(fd,'===========================================4654654564');
					
					this.paramData.ossResourceClassifyId && fd.append('ossResourceClassifyId',this.paramData.ossResourceClassifyId);
					fileUploading(fd).then(res => {
						console.log(res.data,this.imgList,"7777")
						this.imgList = [...res.data, ...this.imgList]
					})
				}
				
				return false
			},
			// 单图上传接口
			getImagePost(image, type) {

			},
			//上传图片成功后
			handleSuccess(response, file, fileList) {
				// // console.log(response, 22222);

				// if (response.return_code == "VERIFICATION") {
				// 	this.$message.error(response.return_msg);
				// } else if (response.return_code == 'TOKEN_IS_INVALID') {

				// 	if (localStorage.getItem('terminal') == 'platform') {
				// 		platRefreshToken({
				// 			refreshToken: localStorage.getItem('refreshToken')
				// 		}).then(res => {
				// 			localStorage.setItem('dynamic', res.data.dynamic);
				// 			localStorage.setItem('refreshToken', res.data.refreshToken);
				// 			localStorage.setItem('head_portrait', res.data.head_portrait);
				// 			localStorage.setItem('shop_name', res.data.shop_name);
				// 			localStorage.setItem('terminal', res.data.terminal);
				// 			store.commit('LOGIN_IN', res.data.token);
				// 			window.location.reload()
				// 		});
				// 	} else if (localStorage.getItem('terminal') == 'shop') {
				// 		shopRefreshToken({
				// 			refreshToken: localStorage.getItem('refreshToken')
				// 		}).then(res => {
				// 			localStorage.setItem('dynamic', res.data.dynamic);
				// 			localStorage.setItem('refreshToken', res.data.refreshToken);
				// 			localStorage.setItem('head_portrait', res.data.head_portrait);
				// 			localStorage.setItem('shop_name', res.data.shop_name);
				// 			localStorage.setItem('terminal', res.data.terminal);
				// 			store.commit('LOGIN_IN', res.data.token);
				// 			window.location.reload()
				// 		});
				// 	} else if (localStorage.getItem('terminal') == 'channel') {
				// 		channelRefreshToken({
				// 			refreshToken: localStorage.getItem('refreshToken')
				// 		}).then(res => {
				// 			localStorage.setItem('dynamic', res.data.dynamic);
				// 			localStorage.setItem('refreshToken', res.data.refreshToken);
				// 			localStorage.setItem('head_portrait', res.data.head_portrait);
				// 			localStorage.setItem('shop_name', res.data.shop_name);
				// 			localStorage.setItem('terminal', res.data.terminal);
				// 			store.commit('LOGIN_IN', res.data.token);
				// 			window.location.reload()
				// 		});
				// 	}

				// } else {
				// 	//this.imgList.push(...response.data);
				// 	console.log(response.data,".response.data")
				// 	this.imgList = [...response.data, ...this.imgList]
				// 	// this.getCatImgs()
				// }
			},
			// 点击切换分类
			changeCat(index) {
				//console.log(index, "6666")
				this.isActive = index;
				if (index > 0) {
					//做加密操作
					let dd = {
						ossResourceClassifyId: this.cats[index].oss_resource_classify_id,
					};
					this.paramData = dd;
					//console.log(this.paramData, 新增商品11)
				} else {
					this.paramData = {};
				}
				this.defaultCatId = this.cats[index].oss_resource_classify_id;
				this.search.name = "";
				// 掉接口
				this.getCatImgs();
			},
			//删除某个分组
			delGroup(data) {
				// console.log(index);
				this.$confirm("此操作将删除该分类下所有图片, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						// 掉接口
						delClassify({
								ossResourceClassifyId: data.oss_resource_classify_id,
							})
							.then((res) => {
								// console.log("del", res);
								// this.cats.splice(index, 1);
								this.getCats();
								this.$message({
									type: "success",
									message: "删除成功!",
								});
							})
							.catch((err) => {
								this.$message.error(err.return_msg);
							});
					})
					.catch(() => {
						// this.$message({
						//   type: "info",
						//   message: "已取消删除",
						// });
					});
			},
			//编辑某个分组
			editGroup(data) {
				this.classify_name = '';
				this.oss_resource_classify_id = '';
				this.pid = '';
				this.level='';
				//console.log(data, "datadata");
				this.isAdd = !this.isAdd;
				if (data.pid == 0) {
					this.groupNames = '编辑分组';
					this.level='1';
					this.classify_name = data.classify_name;
					this.oss_resource_classify_id = data.oss_resource_classify_id;
				} else {
					this.level='';
					this.groupNames = '编辑子分组';
					this.classify_name = data.classify_name;
					this.oss_resource_classify_id = data.oss_resource_classify_id;
					this.pid = data.pid.toString();
				}
				this.curIndex = data.oss_resource_classify_id;
				// this.groupName.name = this.cats[index].classify_name;
				// this.groupName.id = this.cats[index].oss_resource_classify_id;

			},
			// 确定移动
			moveSubmit() {
				 let data=JSON.parse(JSON.stringify(this.moveGroups));
				 data.ossResourceClassifyId=data.ossResourceClassifyId[1];
				classifyUp(data)
					.then((res) => {
						// console.log("moveOk", res);
						// 在本分组-删除该图片
						this.imgList.splice(this.moveGroups.imgIndex, 1);
						this.moveShow = !this.moveShow;
					})
					.catch((err) => {
						this.$message.error(err.return_msg);
					});
			},
			// 取消移动
			moveClose() {
				this.moveGroups = {};
				this.moveShow = !this.moveShow;
				//  this.cats = [...this.originCats];
			},
			//关闭分组
			addClose() {
				this.isAdd = false;
				this.groupNames = '添加分组';
				this.classify_name = '';
				this.oss_resource_classify_id = '';
				this.pid = ''

			},
			// 确定分组
			addSubmit() {
				// console.log(this.curIndex,111)
				// return
				if (!this.classify_name) {
					this.$message("请输入分组名称");
					return;
				}
				let data = {
					classifyName: this.classify_name, //分类名称
					ossResourceClassifyId: this.oss_resource_classify_id, //分类id
					pid: this.pid //pid
				};
				// data.classifyName = this.classify_name;//分类名称
				// data.ossResourceClassifyId = this.oss_resource_classify_id;//分类id
				// data.pid=this.oss_resource_classify_id;//pid
				data = this.tools.filterParams(data);
				//console.log(data, "22222")
				//return;
				saveClassify(data)
					.then((res) => {
						// console.log("save", res);
						// 判断是编辑还是新增
						// console.log(data, 4545);
						// return
						if (data.id) {
							this.getCats();
							//this.cats[this.curIndex].name = data.name;
							// this.cats.splice(this.curIndex, 1, {
							// 	classify_name: data.name,
							// 	oss_resource_classify_id: res.data,
							// });
						} else {
							// this.cats.splice(1, 0, {
							// 	classify_name: data.name,
							// 	oss_resource_classify_id: res.data,
							// });
							this.getCats();
						}
					})
					.catch((err) => {
						this.$message.error(err.return_msg);
					});
				this.isAdd = !this.isAdd;

			},
			// 新建分组
			createGroup() {
				this.isAdd = !this.isAdd;
				// if (this.groupNames == '创建分组') {
				// 	this.classify_name = '';
				// 	// this.oss_resource_classify_id='';
				// 	// this.pid=''
				// }
				if (this.level == '1') {
					this.classify_name = '';
					//this.oss_resource_classify_id='';
				}
			},
			// 点击取消
			closeImg() {
				this.$emit("closeImg", false);
			},
			// 点击确定
			okImg() {
				// 判断是否勾选图片
				let isChecked = this.imgList.some((res) => res.checked);
				if (!isChecked) {
					this.$message("请勾选图片");
					return;
				}
				let list = this.imgList.filter((res) => res.checked);
				// console.log( this.imgList,list);
				this.$emit("okImg", list);
			},
			// 搜索图片
			async searchImgs() {
				this.imgList = [];
				// console.log(this.search, 5555);
				// 根据分类判断是搜索分组还是搜索图片
				if (this.search.sort2 == 2) {
					// 搜索分组
					this.getCats(1);
				} else {
					//判断是否需要查询
					// if(this.search.sort2){
					//     //查询所有分类
					// await this.getCats();
					// }
					// 搜索图片
					//获取当前选择的分类id
					this.defaultCatId =
						this.cats[this.isActive].oss_resource_classify_id || "";
					this.getCatImgs();
				}
				// console.log(this.search);
			},
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getCatImgs();
				// this.getList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getCatImgs();
			},
		},
	};
</script>
<style lang="scss" scoped>
	.custom-tree-node {
	    flex: 1;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    font-size: 14px;
	    padding-right: 10px;
	    height:100%
	 }
	.top_right ::v-deep.el-input-group {
		width: 300px;
	}

	.uploads ::v-deep.el-dialog {
		border-radius: 10px;
	}

	.uploads ::v-deep.el-dialog__header {
		background: #f1f5f6;
		border-radius: 10px 10px 0 0;
	}

	.uploads ::v-deep.el-dialog__body {
		padding: 0;
	}

	.add_groups ::v-deep.el-dialog__body {
		width: 200px;
		margin: 10px auto;
	}

	.el_select ::v-deep.el-input__inner {
		font-size: 12px;
	}

	.top_left ::v-deep.el-upload--text {
		width: auto;
		height: auto;
		margin-right: 20px;
		border: none;
		background: none;
	}

	.uploads ::v-deep.el-dialog__title {
		font-size: 14px;
	}

	.blo_text ::v-deep.el-input__inner {
		background: none;
		height: 30px;
		line-height: 30px;
		border-radius: 0;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.shadow_inp ::v-deep.el-input__inner {
		border: none;
	}

	.move_imgs ::v-deep.el-dialog__body {
		text-align: center;
		margin: 20px 0 10px;
	}

	.up_top ::v-deep.el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	.up_top ::v-deep.el-input__icon {
		line-height: 32px;
	}

	.upload-demo {
		background: none;
	}

						
					
	.uploads {
		.up_top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 14px;
			background: #f1f5f6;
			padding: 14px 20px;

			.top_left {
				display: flex;
				align-items: center;
				height: 32px;
			}

			.top_right {
				display: flex;
				align-items: center;

				label {
					margin-right: 10px;
					font-size: 12px;
					color: #333;
					flex-shrink: 0;
				}

				.el_select {
					width: 120px;
					margin-right: 10px;
					font-size: 12px;
				}
			}
		}

		.up_bot {
			display: flex;

			.bot_left {
				overflow: auto;
				height: 340px;
				width: 180px;
				flex-shrink: 0;
				margin-left: 20px;
					
				.left_blo {
					/* height: 38px; */
					border-radius: 2px;
					padding: 10px;
					color: #333333;
					cursor: pointer;
					font-size: 14px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.blo_cats {
						display: none;
						cursor: pointer;
					}
					

					&:hover {
						background: #f2f2f6;

						.blo_cats {
							display: block;
						}
						
					}
					
				}

				.actives {
					background: #f2f2f6;
					position: relative;
				}
			}

			.bot_right {
				flex-grow: 1;
				margin-left: 10px;

				.right_top {
					height: 300px;
					overflow-y: auto;
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 20px;

					.top_blo {
						width: 160px;
						margin-right: 10px;
						height: 130px;
						position: relative;
						margin-bottom: 10px;

						&:hover {
							.blo_tool {
								display: block;
							}

							.el-input__inner {
								border: none;
							}
						}

						.blo_div {
							width: 100%;
							height: 100%;
							position: absolute;
							background: rgba(116, 84, 84, 0.6);
							top: 0;
							left: 0;
							border-radius: 2px;

							img {
								width: 50px;
								height: 50px;
								left: 50%;
								margin-left: -25px;
								top: 50%;
								margin-top: -25px;
								position: relative;
							}
						}

						.blo_img {
							width: 100%;
							height: 100px;
							display: block;
						}

						.blo_text {
							color: #333333;
							font-size: 12px;
							// border: 1px solid #eeeeee;
							// border-radius: 0 0 2px 2px;
							// padding: 7px 10px;
						}

						.blo_tool {
							cursor: pointer;
							display: none;
							position: absolute;
							right: 6px;
							background: #409eff;
							top: 6px;
							border-radius: 50px;
							width: 16px;
							height: 16px;
							text-align: center;
							z-index: 100;
						}
					}

					.top_blo:nth-child(4n) {
						margin-right: 0;
					}
				}
			}
		}
	}
</style>
