<template>
	<el-container class="container_h">
		<!--左边菜单-->
		<el-aside width="120px"  class="asides">
			   <div class="tophead" >
                <div class="aside-logo" >
                <el-avatar :size="40" :src="head_portrait" style="margin-top:10px" />
                </div>
            </div>
			<v-sidebar @hasMenu="hasMenu" :menuList="sidebarMenu" style="margin-top:65px"></v-sidebar>
		</el-aside>
		<el-main style="overflow: hidden">
			<el-container>
					<el-header>
						<v-head></v-head>
					</el-header>
					<el-container class="content-box_bg">
						<el-aside width="120px" v-show="getHasMenu==true">
							<!---副菜单-->
							<v-subMenu @hasMenu="hasMenu"  :menuList="sidebarMenu"></v-subMenu>
						</el-aside>
						<el-main class="mains">
							<el-breadcrumb separator="/" :style="{'padding':crumbList.map(item => item.name == 'container').length==2?'0px':'15px 0px 15px 20px'}">
								<transition-group name="breadcrumb">
									<template v-for="(route, i) in crumbList">
										<el-breadcrumb-item
											:key="route.name"
											:to="{ name: route.name }"
											v-if="route.name != 'container' && route.meta.name != '概况'"
											:class="{ 'is-last-link': i == crumbList.length - 1 }">
											{{ route.meta.name }}
										</el-breadcrumb-item>
									</template>
								</transition-group>
							</el-breadcrumb>
							<div :style="{'marginTop':getHasMenu==true?'40px':'0px'}">
								<router-view />
							</div>
						</el-main>
					</el-container>
			</el-container>
		</el-main>
	</el-container>

		 <!-- <div class="wrapper">
		 	<div class="wrapper-content">
				
		 		<v-sidebar @hasMenu="hasMenu" :menuList="sidebarMenu"></v-sidebar>
		 		<div class="content-box">
		 			<v-head></v-head>
					
		 			<div class="content" :style="{paddingLeft:getHasMenu==true?'134px':'0px',marginTop:crumbList.map(item => item.name == 'container').length==2?'0px':'53px'}">
		 				
						<v-subMenu @hasMenu="hasMenu"  :menuList="sidebarMenu"></v-subMenu>
		 				<el-breadcrumb separator="/" :style="{'padding':crumbList.map(item => item.name == 'container').length==2?'0px':'15px 0px 15px 20px'}">
		 				    <transition-group name="breadcrumb">
		 				       
		 				        <template v-for="(route, i) in crumbList">
		 				            <el-breadcrumb-item
		 				                :key="route.name"
		 				                :to="{ name: route.name }"
		 				                v-if="route.name != 'container' && route.meta.name != '概况'"
		 				                :class="{ 'is-last-link': i == crumbList.length - 1 }">
		 				                {{ route.meta.name }}
		 				            </el-breadcrumb-item>
		 				        </template>
		 				    </transition-group>
		 				</el-breadcrumb>
		 				<transition name="move" mode="out-in" style="margin-left:124px;margin-bottom:50px">
		 					<router-view>
		 					</router-view>
		 				</transition>
		 			</div>
		 		</div>
		 	</div>
		 </div> -->
</template>
<script>
	import { mapState } from 'vuex'
	import bus from './bus';
	import vHead from './Header.vue';
	import vSidebar from './Sidebar.vue';
	import vSubMenu from './SubMenu.vue'
	export default {
		data() {
			return {
				head_portrait:localStorage.getItem('head_portrait'),
				getHasMenu: false,
				// subMenuItemList: null
			}
		},
		components: {
			vHead,
			vSidebar,
			vSubMenu
		},
		computed: {
		    ...mapState(['isSidebarNavCollapse']),
			...mapState(['isSidebarNavCollapse', 'crumbList']),
		    ...mapState('permission', ['sidebarMenu', 'currentMenu'])
		},
		mounted() {
			bus.$on('hasMenu', msg => {
				this.getHasMenu = msg;
			});
		},
		methods: {
			hasMenu(val) {
				this.getHasMenu = val;
			}
		}

	};
</script>


<style lang="less" scoped>
.tophead{
	position: fixed;
	z-index:1200;
	height: 60px;
    width: 120px;
    top: 0px;
    z-index: 1200;

}
.el-container{
  height: 100%;
 
}
.container_h {
//   background: #fff;
  // background: rgb(245, 247, 249);
  .asides {
   	height:calc(100% - 0px);
    transition: all 0.5s;
    background-color: #22242F;
    overflow-y: auto;
    overflow-x: hidden;
    .aside-logo {
      height: 59px;
      line-height: 59px;
      text-align: center;
      color: white;
      cursor: pointer;
      position: relative;
    z-index: 999;
    background: #22242F;
      border-bottom: 1px #cccccc solid;
      .logo-image {
        width: 38px;
        height: 38px;
        border-radius: 100%;
        top: 12px;
        margin-right: 5px;
      }
      .logo-name {
        font-size: 20px;
        font-weight: bold;
        padding: 2px;
      }
    }
    .mainmenu{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            position: relative;
            font-size: 14px;
            color: #eee;
            font-weight: 400;
            text-transform: none;
            display: block;
            margin: 10px 0;
            padding: 10px 20px;
            position: relative;
            cursor: pointer;
            line-height: 1.42857143;
            -webkit-transition: background-color .2s ease-in-out 0;
            -webkit-transition: background-color .2s ease-in-out 0s;
            transition: background-color .2s ease-in-out 0s;
        }
        li.active {
		    color: #fff;
	    }

    }
   .mainmenu li.active:after {
		content: "";
		width: 4px;
		background: #fff;
		border-radius: 2px;
		position: absolute;
		right: 10px;
		top: 25%;
		bottom: 25%;
	}
 
  }
  .el-aside::-webkit-scrollbar {
    width: 0px;
  }

    .el-header {
    background: white;
    line-height: 60px;
    font-size: 24px;
    border-bottom: 1px #cccccc solid;
    .header-collapse {
      cursor: pointer;
    }
  }
  .mains{
    //   background-color: #fff;
      height:calc(100% - 20px);
      // overflow-y: scroll;
     
  }
  .el-main {
    padding: 0;
    // background: #fff;
     // overflow: hidden;
    // overflow-x: hidden;
     overflow-y: auto;
  }
  .el-main::-webkit-scrollbar {
    width: 0px;
  }
}
	.wrapper {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.wrapper-content {
		display: flex;
	}
	
	.content-box_bg{
		background-color: #F2F2F6;
	}
	.content-box content{
		padding-bottom: 0!important;
	}
	.el-breadcrumb{
			position: fixed;
			width: 100%;
			top: 60px;
		    font-size: 14px;
		    line-height: 1;
			z-index: 999;
		    background: #fff;
			border-left-top-radius: 8px;
			border-right-top-radius: 8px;
		  /* padding:15px 0px 15px 20px; */
			/* border-bottom: 1px solid ##ECECEC;
			box-shadow: 0px 2px 2px 0px #ECECEC, 0px 2px 2px 0px rgba(224, 224, 224, 0.5); */
	}
</style>
