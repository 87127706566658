import {MessageBox,Message,Loading} from 'element-ui';


/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
function createUniqueString() {
	const timestamp = +new Date() + ''
	const randomNum = parseInt((1 + Math.random()) * 65536) + ''
	return (+(randomNum + timestamp)).toString(32)
}

/**
 * 过滤空参数
 * @return {string} ojgdvbvaua40
 */
 function filterParams(params) {
	for (const i in params) {
	  if (params[i] === null || params[i] === undefined || params[i] === '' || params[i].length == 0) {
		delete params[i];
	  }
	}
	return params;
  }

/**
 * 过滤图片前缀
 * @return {string} ojgdvbvaua40
 */
function filterImgPre(params, type) {
	if (params) {
		return params.substr(params.lastIndexOf('/') + 1, params.length);
	} else {
		return params
	}
}
/**
 * 表格时间格式化
 */
function formatDate(cellValue) {
	if (cellValue == null || cellValue == "") return "";
	var date = new Date(cellValue)
	var year = date.getFullYear()
	var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
	var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}
/**
 * 数字存储大小格式化
 * @param {number} num 存储大小 单位：Byte
 * @param {number} digits 保留几位小数
 * @return {string} 2MB
 */
function toStorage(num, digits) {
	digits = digits || 2
	if (num < 1024) {
		return num + 'B'
	}
	num = (num * 1000 / 1024)
	const si = [{
			value: 1E18,
			symbol: 'E'
		},
		{
			value: 1E15,
			symbol: 'P'
		},
		{
			value: 1E12,
			symbol: 'T'
		},
		{
			value: 1E9,
			symbol: 'G'
		},
		{
			value: 1E6,
			symbol: 'M'
		},
		{
			value: 1E3,
			symbol: 'K'
		}
	]
	for (let i = 0; i < si.length; i++) {
		if (num >= si[i].value) {
			return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
				si[i].symbol + 'B'
		}
	}
}

let loadingInstance = null

function openLoading() {
	loadingInstance = Loading.service({
		text: '拼命加载中',
		'background': 'rgba(0, 0, 0, 0.6)'
	})
}

function closeLoading() {
	if (loadingInstance) {
		loadingInstance.close()
	}
}

//element Message 消息提示

//消息提示框
function promptBox(title, status) {
	Message({
		showClose: true,
		message: title,
		type: status
	});
}

//手机号码中间四位隐藏星号显示
function hideMobile(mobile) {
	return mobile.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2")
}


//数字每千位加逗号
function commafy(num) {
	return num && num.toString()
		.replace(/\d+/, function(s) {
			return s.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
		})
}
//commafy(19999999) // "19,999,999"




//把时间戳转为为普通日期格式
function getLocalTime(nS) {
	return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
}
//getLocalTime("1608628527") // "2020/12/22 下午5:15 "


// 正则校验
function verification(type, text) {
	let reg = null;
	switch (type) {
		case 'phone':
			reg = /^1[3456789]\d{9}$/;
			break;
		case 'password':
			reg = /^\S{6,20}$/;
			break;
		case 'code':
			reg = /^\d{4,6}$/;
			break;
		case 'num':
			reg = /^(([1-9][0-9]|[0-9])(\.\d{1,2})?|0\.\d{1,2}|100)$/;
			break;
		case 'ID':
			reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
			break;
		case 'name':
			reg = /^[a-zA-Z\u4e00-\u9fa5_]+$/;
			break;
	}
	return reg.test(text);
}
import store from '@/store/index'

function refreshUrl(url) {
	let oldRouter = JSON.parse(localStorage.getItem('routeItem'));
	oldRouter.forEach((res, index) => {
		if (res.path == "/marketadmin") {
			res.children.forEach((item, iIndex) => {
				if (item.fullPath == url) {
					item.hidden = false;
				}
			})
		}
	});
	localStorage.setItem('routeItem', JSON.stringify(oldRouter))
	store.commit("permission/SET_MENU", oldRouter);
}

function confirm(text) {
	return new Promise((resolve, reject) => {
		MessageBox.confirm(text, '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			resolve(true)
		}).catch(() => {
			// Message.info('已取消')
			reject(false)
		})
	}).catch(() => {
		// 可对错误进行处理
	})
}

function handleInput(value) {
   if (value != '') {
        if (value.indexOf('.') > -1) {
       	      this.form.ProjectBudget = value.slice(0, value.indexOf('.') + 3)
         } else { 
              this.form.ProjectBudget = value
         }
    }
}
function timeFormate(timeStamp) {
		 let year = new Date(timeStamp).getFullYear();
		 let month =new Date(timeStamp).getMonth() + 1 < 10? "0" + (new Date(timeStamp).getMonth() + 1): new Date(timeStamp).getMonth() + 1;
		 let date =new Date(timeStamp).getDate() < 10? "0" + new Date(timeStamp).getDate(): new Date(timeStamp).getDate();
		//  let hh =new Date(timeStamp).getHours() < 10? "0" + new Date(timeStamp).getHours(): new Date(timeStamp).getHours();
		//  let mm =new Date(timeStamp).getMinutes() < 10? "0" + new Date(timeStamp).getMinutes(): new Date(timeStamp).getMinutes();
		// let ss =new Date(timeStamp).getSeconds() < 10? "0" + new Date(timeStamp).getSeconds(): new Date(timeStamp).getSeconds();
	// return	 year + "-" + month + "-" + date +""+" "+hh+":"+mm+":"+ss
	return	 year + "-" + month + "-" + date
}
//设置cookie
function setCookie(c_name, c_pwd, exdays) {
	var exdate = new Date(); //获取时间
	exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
	//字符串拼接cookie
	window.document.cookie = "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
	window.document.cookie = "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
}
	//清除cookie
function clearCookie() {
	this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
}
//获取地址栏参数
function GetUrlParam(name) {
  var url = window.location.href;

  let params = url.substr(url.lastIndexOf("?") + 1).split("&");
  for (let i = 0; i < params.length; i++) {
    let param = params[i];
    let key = param.split("=")[0];
    let value = param.split("=")[1];
    if (key === name) {
      return value;
    }
  }
}


export default {
	createUniqueString,
	toStorage,
	filterParams,
	filterImgPre,
	openLoading,
	closeLoading,
	promptBox,
	hideMobile,
	commafy,
	getLocalTime,
	verification,
	formatDate,
	refreshUrl,
	confirm,
	handleInput,
	timeFormate,
	setCookie,
	clearCookie,
	GetUrlParam,

}
