import axios from '@/config/httpConfig'
import qs from  'qs'
//获取分组列表
export const getClassify=(data)=> {
    return axios('post','/Oss/platform/getClassify', data);
}
//获取素材库图片列表
export const classifyImagesList=(data)=> {
    return axios('post','/Oss/platform/classifyImagesList', data);
}
//分组删除
export const delClassify=(params)=> {
    return axios('get','/Oss/platform/delClassify', params)
}
// 新增/修改分组名称
export const saveClassify=(data)=> {
    return axios('post','/Oss/platform/saveClassify', data);
}
// 删除图片
export const imagesNameDel=(data)=> {
    return axios('post','/Oss/platform/imagesNameDel', data);
}
// 修改图片分类
export const classifyUp=(data)=> {
    return axios('post','/Oss/platform/classifyUp', data);
}
// 修改图片名称
export const imagesNameUp=(data)=> {
    return axios('post','/Oss/platform/imagesNameUp', data);
}
// 上传图片多张或单张
export const fileUploading=(data)=> {
    return axios('post','/Oss/platform/fileUploading', data,true);
}
