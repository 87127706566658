/**
 *
 * @param  {Array} userRouter 后台返回的用户权限json
 * @param  {Array} allRouter  前端配置好的所有动态路由的集合
 * @return {Array} realRoutes 过滤后的路由
 */

export function recursionRouter(userRouter = [], allRouter = []) { //userRouter = [] 有权限  权限值
    var realRoutes = allRouter
        .filter(item => userRouter.includes(item.name))
        .map(item => ({
            ...item,
            children: item.children
                ? recursionRouter(userRouter, item.children)
                : null
        }))
    return realRoutes
}

/**
 *处理redirect为自己第一个
 * @param {Array} arr 拥有的所有权限路由
 *
 */
export function routerDispose(arr){ 
	arr.forEach(item => {
		if(item.redirect){
			let redirectStrArr = item.redirect.split("/");
			redirectStrArr.pop()
			redirectStrArr.push(item.children[0].path)
			item.redirect = redirectStrArr.join("/")
			item.children = routerDispose(item.children)
		}
	});
	return arr
}
/**
 *
 * @param {Array} arr 拥有的所有权限路由
 *
 */
export function routerIntroduce(arr=[]){
    let fanhui = [...arr];
    fanhui.forEach(item=>{
        item.component =  routerCom(item.component)
        item.children = item.children?routerIntroduce(item.children):null
    })
    return fanhui
}
function routerCom(path) {
    if(typeof path == "string"){
        let path1=path.replace("@/components/","");
        return (resolve) => require([`@/components/${path1}`], resolve)
    }else{
        return path
    }
}


/**
 *
 * @param {Array} routes 用户过滤后的路由
 *
 * 递归为所有有子路由的路由设置第一个children.path为默认路由
 */
export function setDefaultRoute(routes) {
    routes.forEach((v, i) => {
        if (v.children && v.children.length > 0) {
            v.redirect = { name: v.children[0].name }
            setDefaultRoute(v.children)
        }
    })
}


