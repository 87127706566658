<template>
    <div>
        <div class="wrapper-main" style="padding:0px">
            <div class="wrapper-cont" style="padding-bottom:0px">
                <el-card shadow="never">
                    <div slot="header" class="clearfix">
                        <div class="times">实时概况 <span v-if="userinfo.user">更新时间：{{ userinfo.user.agent_at }}</span>
                            <div class="common-btn" style="float: right">
                                <el-button plain size="small" :class="{ 'btn_active': flag === 0 }" @click="changeflag(0)">今日
                                </el-button>
                                <el-button plain size="small" :class="{ 'btn_active': flag === -1 }"
                                    @click="changeflag(-1)">全部
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="shoptypes">
                        <!-- <div class="types_1">
                            <div class="titles">合伙人</div>
                            <div class="typemain">
                                <div class="typeitem">
                                    <p>粉丝业绩(元)</p>
                                    <p class="moneys" v-if="userinfo.all">{{userinfo.all.team_achievement}}</p>
                                    <p style="color:#409EFF" v-if="userinfo.all && flag==0">昨日：{{userinfo.all.team_achievement_incre}}%</p>
                                </div>
                                <div class="typeitem">
                                    <p>粉丝人数(个)</p>
                                    <p class="moneys" v-if="userinfo.all">{{userinfo.all.fans_count}}</p>
                                    <p style="color:#409EFF"  v-if="userinfo.all && flag==0">昨日：{{userinfo.all.fans_count_incre}}%</p>
                                </div>
                                <div class="typeitem">
                                    <p>预估收益(元)</p>
                                    <p class="moneys" v-if="userinfo.all">{{userinfo.all.commission_sum}}</p>
                                    <p style="color:#409EFF"  v-if="userinfo.all && flag==0">昨日：{{userinfo.all.commission_sum_incre}}%</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="types_2">
                            <div class="titles dataTitle">门店</div>
                            <div class="typemain">
                                <div class="typeitem">
                                    <p>支付订单数(个)</p>
                                    <p class="moneys">{{ shopinfo.orderCount }}</p>
                                    <p style="color:#409EFF" v-if="flag == 0">昨日：{{ shopinfo.orderCountIncre }}%</p>
                                </div>
                                <!-- <div class="typeitem">
                                    <p>预约订单数(个)</p>
                                    <p class="moneys">{{ shopinfo.unHxOderCount }}</p>
                                    <p style="color:#409EFF" v-if="flag == 0">昨日：{{ shopinfo.unHxOderCountIncre }}%</p>
                                </div> -->
                                <div class="typeitem">
                                    <p>成交金额(元)</p>
                                    <p class="moneys">{{ shopinfo.orderSum }}</p>
                                    <p style="color:#409EFF" v-if="flag == 0">昨日：{{ shopinfo.orderSumIncre }}%</p>
                                </div>
                                <div class="typeitem">
                                    <p>新增会员(人)</p>
                                    <p class="moneys">{{ shopinfo.addUser }}</p>
                                    <p style="color:#409EFF" v-if="flag == 0">昨日：{{ shopinfo.addUserIncre }}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-card>


                <!--交易走势-->
				<div class="data-cont" style="margin-top: 10px;">
					<el-row class="dataTitle">交易走势</el-row>
					<div style="height: 300px;position: relative;">
						<el-radio-group v-model="tabPosition" size="small" @change="changeTrade"
							style="position: absolute;top:15px; right: 4%; z-index: 9999;">
							<el-radio-button label="week">本周</el-radio-button>
							<el-radio-button label="month">本月</el-radio-button>
						</el-radio-group>
						<el-tabs :tab-position="tabPosition">
							<div id="personDetail" ref="personDetail" style="width: 100%; height: 280px;"></div>
						</el-tabs>
					</div>
				</div>



                <!--销售商品榜单-->
				<div class="data-cont" style="margin-bottom:50px">
					<el-row class="dataTitle" style="margin-bottom: 0;">销售商品榜单</el-row>
					<div style="position: relative; height: 40px;">
						<el-radio-group v-model="tabTable" size="small" @change="changeSales"
							style="position: absolute;top:0px; right: 4%;">
							<el-radio-button label="week">本周</el-radio-button>
							<el-radio-button label="month">本月</el-radio-button>
						</el-radio-group>
					</div>
					<el-tabs :tab-position="tabTable">
						<el-table :data="tableData" style="width: 100%" max-height="380"
							:header-cell-style="{ background: '#f3f8fe', color: '#606060' }" stripe border
							 class="table_one">
							<el-table-column type="index" width="80" align="center" label="排名">
							</el-table-column>
							<el-table-column min-width="280" label="商品名称" align="left">
								<template slot-scope="scope">
									<div class="el_names">
										<img :src="scope.row.image.split(',', 1)" class="el_img" />
										<div>{{ scope.row.goodsName }}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="cost" label="结算价" sortable="cost" align="center">
							</el-table-column>
							<el-table-column prop="stock" label="总库存" sortable="stock" align="center">
							</el-table-column>
							<el-table-column label="实际销量" align="center" prop="salesVolume" sortable="salesVolume">
							</el-table-column>
							<el-table-column label="成交额" align="center" prop="sales" sortable="sales">
								<template slot-scope="scope">
									<div style="color: #0091ff">{{ scope.row.sales }}</div>
								</template>
							</el-table-column>
						</el-table>
					</el-tabs>
				</div>

                <!-- <el-row :gutter="10" class="mt10">
                    <el-col :span="12">
                        <el-card shadow="never">
                            <div slot="header" class="clearfix">
                                <div class="times">合伙人业绩
                                    <div class="common-btn" style="float: right">
                                        <el-button plain size="small" :class="{ 'btn_active': flag1 === 0}"
                                            @click="changeflag1(0)">今日
                                        </el-button>
                                        <el-button plain size="small" :class="{ 'btn_active': flag1 === 7}"
                                            @click="changeflag1(7)">7日
                                        </el-button>
                                        <el-button plain size="small" :class="{ 'btn_active': flag1 === 30 }"
                                            @click="changeflag1(30)">30日
                                        </el-button>
                                        <el-button plain size="small" :class="{ 'btn_active': flag1 === -1 }"
                                            @click="changeflag1(-1)">全部
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="achievement">
                                <el-tabs v-model="buyer_type" @tab-click="handleLevalClick">
                                    <el-tab-pane label="A" name="A"></el-tab-pane>
                                    <el-tab-pane label="B" name="B"></el-tab-pane>
                                    <el-tab-pane label="C" name="C1"></el-tab-pane>
                                </el-tabs>
                                <div class="achievement_item">
                                    <div class="title" v-if="yjdata.level1">{{yjdata.level1.level_name}}</div>
                                    <div class="itemsval">
                                        <div class="items">
                                            <p>粉丝业绩(元)</p>
                                            <p class="moneys" v-if="yjdata.level1">{{ parseFloat(yjdata.level1.team_achievement).toFixed(2)}}
                                            </p>
                                            <p style="color:#409EFF" v-if="flag1==0 && yjdata.level1">昨日：{{yjdata.level1.team_achievement_incre}}%</p>
                                        </div>
                                        <div class="items">
                                            <p>粉丝人数(个)</p>
                                            <p class="moneys" v-if="yjdata.level1">{{yjdata.level1.fans_count}}</p>
                                            <p style="color:#409EFF" v-if="flag1==0 && yjdata.level1">昨日：{{yjdata.level1.fans_count_incre}}%</p>
                                        </div>
                                        <div class="items">
                                            <p>预估收益(元)</p>
                                            <p class="moneys" v-if="yjdata.level1">{{ parseFloat(yjdata.level1.commission_sum).toFixed(2)}}</p>
                                            <p style="color:#409EFF" v-if="flag1==0 && yjdata.level1">昨日：{{yjdata.level1.commission_sum_incre}}%</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="achievement_item">
                                    <div class="title" v-if="yjdata.level2">{{yjdata.level2.level_name}}</div>
                                    <div class="itemsval">
                                        <div class="items">
                                            <p>粉丝业绩(元)</p>
                                            <p class="moneys" v-if="yjdata.level2">{{ parseFloat(yjdata.level2.team_achievement).toFixed(2)}}
                                            </p>
                                            <p style="color:#409EFF" v-if="flag1==0 && yjdata.level2">昨日：{{yjdata.level2.team_achievement_incre}}%</p>
                                        </div>
                                        <div class="items">
                                            <p>粉丝人数(个)</p>
                                            <p class="moneys" v-if="yjdata.level2">{{yjdata.level2.fans_count}}</p>
                                            <p style="color:#409EFF" v-if="flag1==0 && yjdata.level2">昨日：{{yjdata.level2.fans_count_incre}}%</p>
                                        </div>
                                        <div class="items">
                                            <p>预估收益(元)</p>
                                            <p class="moneys" v-if="yjdata.level2">{{parseFloat(yjdata.level2.commission_sum).toFixed(2)}}</p>
                                            <p style="color:#409EFF" v-if="flag1==0 && yjdata.level2">昨日：{{yjdata.level2.commission_sum_incre}}%</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="achievement_item">
                                    <div class="title" v-if="yjdata.level3">{{yjdata.level3.level_name}}</div>
                                    <div class="itemsval">
                                        <div class="items">
                                            <p>粉丝业绩(元)</p>
                                            <p class="moneys" v-if="yjdata.level3">{{ parseFloat(yjdata.level3.team_achievement).toFixed(2)}}
                                            </p>
                                            <p  style="color:#409EFF" v-if="flag1==0 && yjdata.level3">昨日：{{yjdata.level3.team_achievement_incre}}%</p>
                                        </div>
                                        <div class="items">
                                            <p>粉丝人数(个)</p>
                                            <p class="moneys" v-if="yjdata.level3">{{yjdata.level3.fans_count}}</p>
                                            <p style="color:#409EFF" v-if="flag1==0 && yjdata.level3">昨日：{{yjdata.level3.fans_count_incre}}%</p>
                                        </div>
                                        <div class="items">
                                            <p>预估收益(元)</p>
                                            <p class="moneys" v-if="yjdata.level3">{{ parseFloat(yjdata.level3.commission_sum).toFixed(2)}}</p>
                                            <p style="color:#409EFF" v-if="flag1==0 && yjdata.level3">昨日：{{yjdata.level3.commission_sum_incre}}%</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card shadow="never">
                            <div slot="header" class="clearfix">
                                <div class="times">合伙人奖励
                                    <div class="common-btn" style="float: right">
                                        <el-button plain size="small" :class="{ 'btn_active': flag2 === 0 }"
                                            @click="changeflag2(0)">今日
                                        </el-button>
                                        <el-button plain size="small" :class="{ 'btn_active': flag2 === 7 }"
                                            @click="changeflag2(7)">近7日
                                        </el-button>
                                        <el-button plain size="small" :class="{ 'btn_active': flag2 === 30 }"
                                            @click="changeflag2(30)">近30日
                                        </el-button>
                                        <el-button plain size="small" :class="{ 'btn_active': flag2 === -1 }"
                                            @click="changeflag2(-1)">全部
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="achievement">
                                <div class="achievement_item">
                                    <div class="itemsval">
                                        <div class="items">
                                            <p>直推奖(元)</p>
                                            <p class="moneys">{{parseFloat(jldata.direct_commission).toFixed(2)}}</p>
                                        </div>
                                        <div class="items">
                                            <p>市场奖(元)</p>
                                            <p class="moneys">{{parseFloat(jldata.deep_commission).toFixed(2)}}</p>
                                        </div>
                                        <div class="items">
                                            <p>培育奖(元)</p>
                                            <p class="moneys">{{parseFloat(jldata.keep_commission).toFixed(2)}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="achievement_item">
                                    <div class="itemsval">
                                        <div class="items">
                                            <p>粉丝团队奖(元)</p>
                                            <p class="moneys">{{parseFloat(jldata.team_achievement).toFixed(2)}}</p>
                                        </div>
                                        <div class="items">
                                            <p>分红奖(元)</p>
                                            <p class="moneys">{{parseFloat(jldata.stock_commission).toFixed(2)}}</p>
                                        </div>
                                        <div class="items">
                                            <p>福利奖(元)</p>
                                            <p class="moneys">{{parseFloat(jldata.re_deep_commission).toFixed(2)}}</p>
                                        </div>
                                        <div class="items">
                                            <p>预估福利奖(元)</p>
                                            <p class="moneys">{{parseFloat(jldata.predict_re_deep_commission).toFixed(2)}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row> -->
            </div>
        </div>
    </div>
</template>
<script>
import { viewUserInfo, shopCenter, viewLevel, myView,tradeData,goodsSaleData } from '@/api/merchant/index'
export default {
    data() {
        return {
            flag: -1,
            flag1: -1,
            flag2: -1,
            buyer_type: 'A',
            userinfo: {},
            shopinfo: {},
            yjdata: {},
            jldata: {},
            tabPosition: 'week',
            tabTable: 'week',
            tableData:[]
        }
    },
    mounted() {
        // this.gettopdata();//没维护了
        this.getshopdata();
        //交易走势
		this.getTradeData();
        //商品销售榜单
		this.getGoodsSaleData()
        //合伙人业绩
        // this.getachievement();
        //合伙人奖励
        // this.awardDataInfo()
    },
    methods: {
        		//交易切换
		changeTrade(val) {
			this.tabPosition = val;
			this.getTradeData();
		},
        		//切换销售榜单
		changeSales(val) {
			this.tabTable = val;
			this.getGoodsSaleData();
		},
        //用户信息
        gettopdata() {
            let data = {
                account_shop_id: 0,
                day: this.flag,
            }
            viewLevel(data).then(res => {
                this.userinfo = res.data;
            })
        },
        //门店信息
        getshopdata() {
            let data = {
                account_shop_id: 0,
                day: this.flag,
            }
            shopCenter(data).then(res => {
                this.shopinfo = res.data;

            })
        },
        //交易走势
		getTradeData() {
			let params = {
				filter: this.tabPosition
			}
			tradeData(params).then(res => {
				let data = res.data;
				let tradeT = [], tradeC = [], tradeM = [];
				data.forEach(item => {
					tradeT.push(item.timeStr);
					tradeC.push(item.salesVolume);
					tradeM.push(item.sales);
				});
				this.loadLine(tradeT, tradeC, tradeM);
			})
		},
        //销售榜单
		getGoodsSaleData() {
			let params = {
				filter: this.tabTable,
				field: this.field,
				sort: this.sort
			};
			params = this.tools.filterParams(params);
			goodsSaleData(params).then(res => {
                
				this.tableData = res.data 
			})
		},
        //合伙人业绩
        getachievement() {
            let data = {
                day: this.flag1,
                buyer_type: this.buyer_type,
                level: 0,
                account_shop_id: 0
            }
            viewLevel(data).then(res => {
                this.yjdata = res.data

            })
        },
        //合伙人奖励
        awardDataInfo() {
            let data = {
                day: this.flag2,
                account_shop_id: 0
            }
            myView(data).then(res => {
                this.jldata = res.data

            })
        },
        //用户信息
        changeflag(val) {
            this.flag = val;
            this.gettopdata();
            this.getshopdata();
        },
        //合伙人业绩--时间切换
        changeflag1(val) {
            this.flag1 = val;
            this.getachievement();
        },
        //合伙人奖励--时间切换
        changeflag2(val) {
            this.flag2 = val;
            this.awardDataInfo()
        },
        //合伙人业绩--等级切换
        handleLevalClick(val) {
            this.buyer_type = val.name;
            this.getachievement();

        },
        loadLine(tradeT, tradeC, tradeM) {
			let myChart = this.$echarts.init(this.$refs.personDetail);
			let option = {
				title: {
					// text: "折线图堆叠",
				},
				tooltip: {
					trigger: "axis",
				},
				color: ['#5AD8A6', '#5B8FF9'],
				legend: {
					data: ["成交额", "成交量"],
					textStyle: {
						fontSize: 12,
						color: '#666'
					}
				},
				grid: {
					left: "3%",
					right: "4%",
					bottom: "3%",
					containLabel: true,
				},
				toolbox: {
					feature: {
						saveAsImage: {},
					},
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
					data: tradeT,
				},
				yAxis: {
					type: "value",
				},
				series: [
					{
						name: "成交额",
						type: "line",
						stack: "总量",
						data: tradeM,
						itemStyle: {
							normal: {
								lineStyle: {
									color: '#5AD8A6'
								}
							}
						},
					},
					{
						name: "成交量",
						type: "line",
						stack: "总量",
						data: tradeC,
						itemStyle: {
							normal: {
								lineStyle: {
									color: '#5B8FF9'
								}
							}
						},
					}
				],
			};
			// 使用刚指定的配置项和数据显示图表。
			myChart.setOption(option);

		},
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/platdashboard.scss";
::v-deep.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0;
}
.data-cont{
	 //margin:10px 0px 10px 10px;
	 margin-bottom: 10px;
	padding: 20px;
	background-color: #fff;
	border-radius: 4px;
}
.dataTitle{
	height: 16px;
	border-left:4px solid #2589FF ;
	line-height: 16px;
	padding-left: 10px;
	color: #333;
	font-size: 14px;
	margin-bottom: 20px;
}
.common-btn {
    float: right;

    button {
        display: inline-block;
        width: 70px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #FFF;
        border: 1px solid #DCDFE6;
        color: #606266;
        outline: 0;
        padding: 7px 8px;
        font-size: 14px;
        border-radius: 4px;
        margin-right: 10px;
    }

    .btn_active {
        background: #2589FF !important;
        border-color: #2589FF !important;
        color: #fff !important;
    }
}

.times span {
    font-size: 14px;
    color: #666;
}

.shoptypes {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .types_1 {
        border-right: 1px solid #EEEEEE;
        margin-right: 40px;
    }

    .types_1,
    .types_2 {
        width: 100%;

        .titles {
            height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
        }

        .typemain {
            display: flex;
            margin-top: 20px;
            margin-left: 30px;

            .typeitem {
                flex: 1;

                p {
                    height: 25px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 25px;
                }

                .moneys {
                    height: 38px;
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 38px;
                }
            }
        }
    }
}

.achievement {
    .achievement_item {
        background: #EFF2F5;
        margin-top: 10px;
        padding: 20px 20px;

        .title {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
            margin-bottom: 10px;
        }

        .itemsval {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .items {
                flex: 1;

                p {
                    height: 25px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 25px;
                }

                .moneys {
                    height: 38px;
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 38px;
                }
            }
        }
    }
}
</style>
