import axios from '@/config/httpConfig'
import qs from  'qs'
//登陆
export const shopAccountLogin=(data)=> {
  return axios('post','/Account/shop/shopAccountLogin', data);
}
//获取平台登录者账号信息
export const userBasicsInfo=(params)=> {
	  return axios('get','/Account/shop/getAccountShopInfo', params)
}
//修改平台登录者账号信息
export const upUserBasicsINfo=(data)=> {
    return axios('post','/Account/shop/revampShopAccountInfo', data);
}
//发送验证码
export const loginSmsCodeSend=(params)=> {
	  return axios('get','/Account/shop/loginSmsCodeSend', params)
}
//门店-获取微信扫码登录链接
export const getAuthorizationUrl=(params)=> {
	 return axios('get','/Account/shop/getAuthorizationUrl', params);
}
//门店账号微信登录
export const wxTouristShopAccountLogin=(data)=> {
    return axios('post','/Account/shop/wxTouristShopAccountLogin', data);
}
//刷新令牌
export const shopRefreshToken=(data)=> {
    return axios('post','/TokenCheck/shop/refreshToken', data);
}
//退出登录
export const quitLoginShop=(params)=> {
	  return axios('get','Account/shop/quit', params)
}

