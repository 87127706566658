import Vue from 'vue'
import App from './App'
import store from '@/store/index'
import router from './router'
//地址插件
import {
	pcaa
} from 'area-data-vue';
import VueAreaLinkage from 'area-linkage-vue';
import 'area-linkage-vue/dist/index.css';

//引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//引用封装方法
import Tools from './utils/tools'
Vue.prototype.tools = Tools;
// //加密解密
// import Encryption from '@/utils/encryption';
// Vue.prototype.encryption = Encryption;
//aes加密解密
import Aes from '@/utils/aes';
Vue.prototype.aes = Aes;
// 引入echarts
import echarts from 'echarts';
import '../node_modules/echarts/map/js/china.js' // 引入中国地图数据
Vue.prototype.$echarts = echarts;

import has from '@/utils/btnPermissions.js';

import Vue2OrgTree from 'vue2-org-tree'
import 'vue2-org-tree/dist/style.css'
Vue.use(Vue2OrgTree)
import VueClipboard from 'vue-clipboard2'
 
Vue.use(VueClipboard)

Vue.prototype.$pcaa = pcaa;
Vue.use(VueAreaLinkage);
import bus from '@/components/common/bus.js'
Vue.use(ElementUI);
// 修改 el-dialog 默认点击遮照为不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.config.productionTip = false

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
	//隐藏二级菜单
	if (to.path == "/home") {
		bus.$emit('hiddenitem', "hide");
	}
	if (to.path == "/shoppingadmin/shoppingindex") {
		bus.$emit('hiddenitem', "hide");
	}
	//返回登录页刷新路由
	//判断是否有token
	let token = store.state.UserToken;
	if (!token) {
		/* 没有token了 */
		if (to.matched.length > 0) {
			next();
		} else {
			store.commit('LOGIN_OUT');
			next({
				path: '/shoplogin'
			})
			
		}

	} else {
		/* 现在有token了 */
		if (!store.state.permission.permissionList) {
			/* 如果没有permissionList */
			store.dispatch('permission/FETCH_PERMISSION').then(() => {
				next({
					path: to.fullPath  //vue-router路由参数刷新消失的问题解决方法  如果to.path 刷新会参数消失
				})
			})
		} else {
			// if (to.path !== '/login') {
			// 	next()
			// } else 
			if (to.path !== '/shoplogin') {
				next()
			} else {
				next(from.fullPath)
			}
		}
	}

})
router.afterEach((to, from, next) => {
	var routerList = to.matched
	store.commit('setCrumbList', routerList)
	store.commit('permission/SET_CURRENT_MENU', to.name); 

	let routeList = store.state.permission.sidebarMenu;
	if (routeList && routeList.length > 0) {
		for (let i = 0; i < routeList.length; i++) {
			let route = routeList[i];
			if ('/' + to.fullPath.split('/')[1] == route.path) {
				bus.$emit('subMenuItem', route.children);
				break;
			}
		}
	};
})
new Vue({
	el: '#app',
	router,
	store,
	components: {
		App
	},
	template: '<App/>'
})
