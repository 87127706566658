import axios from '@/config/httpConfig'
import qs from  'qs'
//获取商户认证详情
export const statistics=(params)=> {
	  return axios('get','Data/platform/statistics', params)
}
//交易走势
export const tradeData=(params)=> {
	  return axios('get','Data/platform/tradeData', params)
}
//销售榜单

export const goodsSaleData=(params)=> {
	  return axios('get','Data/platform/goodsSaleData', params)
}