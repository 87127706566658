import Vue from 'vue'
    
    /**权限指令**/
    const has = Vue.directive('has', {
        update: function (el, binding,  vnode) {
			btnFilter(el, binding,  vnode)
        },
		bind: function (el, binding,  vnode) {
			btnFilter(el, binding,  vnode)
		},
    });
	function btnFilter(el, binding,  vnode){
		const value =binding.value?binding.value.trim():'';//传入参数
		
		// 获取页面按钮权限
		let btnPermissionsArr = vnode.context.$route.meta.btnPermissions || [];
		// 第一个节点中内容
		// const txt = el.firstElementChild.childNodes[0].nodeValue.trim()
		if(!value){
		    //TAB切换按钮
		    Vue.nextTick(() =>{
		        let childrenArr = el.querySelector(".el-tabs__nav").children;
		        for (let i = 0; i < childrenArr.length; i++) { 
		            if(btnPermissionsArr.indexOf(childrenArr[i].innerHTML.trim()) == -1){
		                childrenArr[i].style = 'display:none;'
		            }
		        }
		    } )
		}else{
		    //其他按钮
		    if(btnPermissionsArr.indexOf(value) == -1){
		        Vue.nextTick(() =>{
		            el.style = 'display:none;visibility:hidden;'
		            el.remove()
		        } )
		    }else{
			}
		}
	}
    // export {has}

