<template>
	<div>
		<v-head></v-head>
		<div class="accountInfo-cont">
			<div class="accountInfo-top">
				<div class="left-info">个人账号设置<el-button size="small" type="primary" @click="backHome"
						style="margin-left: 20px;font-size: 16px;" plain>返回首页</el-button>
				</div>
				<div class="right-info">+86-{{ruleFrom.account}}
					<el-button style="margin-left: 20px;font-size: 16px;" type="text" @click="loginout">退出</el-button>
				</div>
			</div>
			<div class="font-main">
				<el-row type="flex" class="row-bg" justify="space-around">
					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="usertype mt30">基础信息</div>
							<el-form ref="ruleFrom" :model="ruleFrom" :rules="rulesFrom" label-width="100px" class="fromCont">
								<el-form-item label="账号:">
									{{ruleFrom.account}}
								</el-form-item>
								<el-form-item label="密码:" prop="password">
									<el-input size="small" type="password"
										style="position: relative;padding-right:40px;z-index: 1;" placeholder="请输入登录密码"
										class="inputwidth"  maxlength="16" v-model="ruleFrom.password">
										<!-- <template slot="append" ><span @click="modifyShow"
												style="color: #409EFF;cursor: pointer;" >修改</span></template> -->
									</el-input>
								</el-form-item>
								<el-form-item label="确认密码" prop="newpassword">
									<el-input size="small" type="password"
										style="position: relative;padding-right:40px;z-index: 1;"  maxlength="16" placeholder="请输入确认密码"
										class="inputwidth" v-model="ruleFrom.newpassword">
									</el-input>
								</el-form-item>
								<el-form-item label="昵称:">
									<el-input size="small" placeholder="请输入昵称" type="text" class="inputwidth"
										v-model="ruleFrom.username"></el-input>
								</el-form-item>
								<el-form-item label="头像:">
									<div class="avatar-uploader1" @click="uploadImg">
										<img v-if="ruleFrom.head_portrait" :src="ruleFrom.head_portrait" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									</div>
								</el-form-item>
								<el-button style="margin-left: 20%;" type="primary" size="small"
									@click="modifyInfo('ruleFrom')">修改</el-button>
							</el-form>
						</div>
					</el-col>

					<el-col :span="6">
						<div class="grid-content bg-purple">
							<div class="usertype mt30">账号信息</div>
							<div class="accout_info  mt30">
								<div class="item">
									上次登录时间：{{accountInfo.last_login_time}}
								</div>
								<div class="item">
									上次登录IP：{{accountInfo.ip}}
								</div>
								<div class="item">
									注册时间：{{accountInfo.created_at}}
								</div>
								<div class="item">
										到期时间：{{accountInfo.end_time}}
								</div>
							</div>
						</div>
					</el-col>
				</el-row>

			</div>
		</div>
		<!--修改密码弹框-->
		<el-dialog title="修改密码" :visible.sync="dialogVisible" width="25%">
			<el-form ref="form" :model="form" :rules="rule" label-width="120px" class="demo-ruleForm">
				<el-form-item label="原密码">
					<el-input size="small"  type="password" v-model="form.old_password" placeholder="原密码"></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="new_password">
					<el-input size="small" type="password" v-model="form.new_password" placeholder="原密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="sure_password">
					<el-input size="small" type="password" v-model="form.sure_password" placeholder="原密码"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="sureModify('form')" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	const sha256 = require('js-sha256').sha256
	import ChooseImg from '../common/uploadImgs.vue'
	import {
		userBasicsInfo,
		upUserBasicsINfo
	} from '@/api/merchant/login.js'
	import vHead from '../common/Header.vue';
	export default {
		data() {
			return {
				dialogVisible: false,
				uploadFlag: false,
				ruleFrom: {
					account: '',
					head_portrait: '',
					username:'',
					password: '',
					newpassword: ''
				},
				rulesFrom:{
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					newpassword: [{
						required: true,
						message: '请确认密码',
						trigger: 'blur'
					}],
				},
				accountInfo:{
					last_login_time:'',
					ip:'',
					created_at:''
				},
				paramsimg: {
					terminal: 'platform',
					classify: '3',
					prefixUrl: true
				},
				myHeaders: {
					token: localStorage.getItem('token')
				},
				form: {
					old_password: '',
					new_password: '',
					sure_password: ''
				},
				rule: {
					new_password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					sure_password: [{
						required: true,
						message: '确认新密码',
						trigger: 'blur'
					}]
				}
			}
		},
		components: {
			vHead,
			ChooseImg
		},
		mounted() {
			this.getUserBasicsInfo()
		},
		methods: {
			uploadImg() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.ruleFrom.head_portrait = val[0].resource_name;
				this.uploadFlag = false;
				
			},
			async getUserBasicsInfo() {
				let params = {}
				try {
					let data = await userBasicsInfo(params)
					if (data.return_code === 'SUCCESS') {
						this.ruleFrom = data.data;
						this.accountInfo.last_login_time=data.data.last_login_time;
						this.accountInfo.ip=data.data.ip;
						this.accountInfo.created_at=data.data.created_at;
						this.accountInfo.end_time = this.tools.formatDate(data.data.end_time*1000) 
						localStorage.setItem('head_portrait', data.data.head_portrait)
					}
				} catch (error) {}
			},
			modifyInfo(ruleFrom) {
				this.$refs[ruleFrom].validate((valid) => {
					if (valid) {
						if (this.ruleFrom.password && this.ruleFrom.password.length < 6) {
							this.$message.warning("密码长度不能少于6位");
							return;
						} else if (this.ruleFrom.password && this.ruleFrom.password.length > 16) {
							this.$message.warning("密码长度不能大于16位");
							return;
						} else if (this.ruleFrom.password && this.ruleFrom.newpassword != '' && this.ruleFrom
							.newpassword != this.ruleFrom.password) {
							this.$message.warning("两次密码输入不一致");
							return;
						}
						let data = JSON.parse(JSON.stringify(this.ruleFrom));
						data.head_portrait = this.tools.filterImgPre(data.head_portrait);
						data.password = sha256(data.password);
						data = this.tools.filterParams(data);
						delete data["newpassword"];
						delete data["account_relevance_info"];
						delete data["balance_fund"];
						upUserBasicsINfo(data).then(res => {
							if (res.return_code == 'SUCCESS') {
								this.$message({
									type: 'success',
									message: '更新成功!'
								});

								this.getUserBasicsInfo()
							}
						}).catch(err=>{
					 this.$message.error(err.return_msg)
					})
					} else {
						//console.log('error submit!!');
						return false;
					}
				});
			},
			//返回页面
			backHome() {
				this.$router.replace('/home')
			},
			//退出登录
			loginout() {
				this.$store.commit('LOGIN_OUT')
				localStorage.removeItem('dataset')
				localStorage.removeItem('nickname')
				localStorage.removeItem('avatar')
				localStorage.removeItem('account_id')
				/* 防止切换角色时addRoutes重复添加路由导致出现警告 */
				this.$router.push('/shoplogin');
				localStorage.setItem("activeName",'first');
				window.location.reload()
			},
			//修改密码弹框
			modifyShow(){
				this.dialogVisible = !this.dialogVisible
			},
			//修改密码
			sureModify(form) {
			 this.$refs[form].validate((valid) => {
					  if (valid) {
						//调接口
					  } else {
						return false;
					  }
					});
			}
		}

	};
</script>

<style scoped="scoped" lang="scss">
	@import "../../assets/css/accountinfo.scss";
</style>
