import Vue from 'vue';
import Router from 'vue-router';


const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

//引入文件
//home
import Home from '@/components/common/Home.vue'
import Dashboard from '@/components/page/dashboard.vue'
import Login from  '@/components/page/login.vue'
import merchantLogin from  '@/components/page/merchantlogin.vue'
import AccountPlatInfo from  '@/components/page/accountPlatInfo.vue'
import AccountChannelInfo from  '@/components/page/accountChannelInfo.vue'
import AccountMerchantInfo from  '@/components/page/accountMerchantInfo.vue'
Vue.use(Router);
export default new Router({
	mode: 'history',
	routes: [
		// {
	    //     path: '/login',
	    //     component: Login,
	    // },
	    // {
	    //     path: '/',
	    //     redirect: '/Login'
	    // },
		{
		   path: '/shoplogin',
		   component: merchantLogin,
		 },
		 {
		 	path: '/accountPlatInfo',
		 	component: AccountPlatInfo,
		 },
		 {
		 	path: '/accountChannelInfo',
		 	component: AccountChannelInfo,
		 },
		 {
		 	path: '/accountMerchantInfo',
		 	component: AccountMerchantInfo,
		 },
	]
});
/* 准备动态添加的路由 */
export const DynamicRoutes = [
	{
        path: '',
        component: Home,
        name: 'container',
        redirect: 'home',
        meta: {
            requiresAuth: true,
            name: '概况'
        },
        children: [{
            id: 1,
            path: '/home',
            component: Dashboard,
            name: 'home',
			// hidden: true,
            meta: {
                name: '概况',
                icon: 'el-icon-s-home'
            }
        }, ]
    }
	
]
