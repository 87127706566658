<template>
	<div>
		<PlatIndex v-if="terminal == 'platform'"></PlatIndex>
		<MerchantIndex  v-else-if="terminal == 'shop'"></MerchantIndex>
		<ChannelIndex  v-else-if="terminal == 'channel'"></ChannelIndex>
	</div>
</template>

<script>
	import PlatIndex from '../pagecomponents/platindex.vue'
	import MerchantIndex from '../pagecomponents/merchantindex.vue'
	import ChannelIndex from '../pagecomponents/channelindex.vue'
	export default {
		components:{
			PlatIndex,
			MerchantIndex,
			ChannelIndex
		},
		name: 'HelloWorld',
		data() {
			return {
				terminal:localStorage.getItem("terminal")
			}
		},
		mounted() {},
		methods: {}
	}
</script>

<style lang="scss" scoped>
	
</style>
