
import router, {
    DynamicRoutes
} from '@/router/index'
import { routerIntroduce } from '@/utils/recursion-router'

export default {
    namespaced: true,
    state: {
        permissionList: null /** 所有路由 */ ,
        sidebarMenu: [] /** 导航菜单 */ ,
        currentMenu: '' /** 当前active导航菜单 */ ,
        control_list: [] /** 完整的权限列表 */ ,
        avatar: '' /** 头像 */ ,
        account: '' /** 用户角色 */,
    },
    getters: {},
    mutations: {
        SET_AVATAR(state, avatar) {
            state.avatar = avatar
        },
        SET_ACCOUNT(state, account) {
            state.account = account
        },
        SET_PERMISSION(state, routes) {
            state.permissionList = routes
        },
        CLEAR_PERMISSION(state) {
            state.permissionList = null
        },
        SET_MENU(state, menu) {
            state.sidebarMenu = menu
        },
        CLEAR_MENU(state) {
            state.sidebarMenu = []
        },
        SET_CURRENT_MENU(state, currentMenu) {
            state.currentMenu = currentMenu
        },
        SET_CONTROL_LIST(state, list) {
            state.control_list = list
        }
    },
    actions: {
        FETCH_PERMISSION({
            commit,
            state
        }) {
            commit('SET_AVATAR', localStorage.getItem('avatar'))
            commit('SET_ACCOUNT', localStorage.getItem('nickname'))
            let routes = routerIntroduce(JSON.parse(localStorage.getItem('dataset'))?JSON.parse(localStorage.getItem('dataset')):[]) //有权限
            let MainContainer = DynamicRoutes.find(v => v.path === '')
            let children = MainContainer.children
            children.push(...routes)
            commit('SET_CONTROL_LIST', [...children])
            
            commit('SET_MENU', children)
            
            
             router.addRoute(MainContainer)
            
            let initialRoutes = router.options.routes;
            commit('SET_PERMISSION', [...initialRoutes, ...DynamicRoutes])
        }
    }
}
