import axios from '@/config/httpConfig'
import qs from  'qs'
//登陆
export const channelLogin=(data)=> {
  return axios('post','/Account/channel/channelAccountLogin', data);
}
//获取平台登录者账号信息
export const userBasicsInfo=(params) => {
	  return axios('get','/Account/channel/getChannelAccountInfo', params)
}
//修改平台登录者账号信息
export const upUserBasicsINfo=(data)=>  {
    return axios('post','/Account/channel/revampChannelAccountInfo', data);
}
//发送验证码
export const loginSmsCodeSend=(params)=>  {
	  return axios('get','/Account/channel/loginSmsCodeSend', params)
}
//服务商刷新令牌
export const channelRefreshToken=(data)=>  {
    return axios('post','/TokenCheck/channel/refreshToken', data);
}
//退出登录
export const quitLoginChannel=(params)=>  {
	  return axios('get','Account/channel/quit', params)
}