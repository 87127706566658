<template>
	<div class="sidebar">
		<!-- <div class="user-avator">
			<img :src="head_portrait" />
		</div> -->
		<ul class="menu">
			<li  v-for="(item,index) in menuList" :key="index" :index="item.name" :class="{ 
					active:'/'+$route.path.split('/')[1]==item.path}" @click="toMenu(item.name,item)" >
				<span class="iconSpan" v-if="!item.hidden">
					<img v-if="item.meta.icon=='el-icon-loading'" style="width: 16px;height: 16px; vertical-align: middle;" src="../../assets/img/shopping.png" />
					<i v-else :class="item.meta.icon"></i>
				</span>
				<span v-if="!item.hidden">{{item.meta.name}}</span>
			</li>
		</ul>

	</div>
</template>

<script>
	import bus from './bus'
	export default {
		props: {
		    menuList: {
		        type: Array,
		        default: () => {
		            return []
		        }
		    }
		},
		data() {
			return {
				current: 0,
				head_portrait:localStorage.getItem('head_portrait'),
			}
		},
		
		mounted() {},
		// beforeDestroy () {
		//     bus.$off('get', 'subMenuItem')
		//   },
		methods: {
			toMenu(name, item) {
				item.children = (item.children && item.children.some(item=>!item.hidden)) ? item.children : [];
					bus.$emit('subMenuItem',item.children || null);
				this.$router.push({ name })
			}
		}
	}
</script>
<style scoped="scoped">
	.sidebar {
		list-style: none;
        margin: 0;
        padding: 0;
	}
	.sidebar::-webkit-scrollbar {
	  display: none;
	}

	.sidebar ul {
		height: 100%;
		overflow: hidden;
	}
.user-avator img{
	margin: 20px auto;
	
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
	.menu {
		width: 120px;
		
		list-style: none;
		position: relative;
		margin: 0;
		padding-left: 0;
		background: #22242f;
	}

	.menu li {
		position: relative;
		font-size: 14px;
		color: #757993;
		font-weight: 400;
		text-transform: none;
		display: block;
		padding: 11px 20px;
		position: relative;
		cursor: pointer;
		line-height: 1.42857143;
		-webkit-transition: background-color .2s ease-in-out 0;
		-webkit-transition: background-color .2s ease-in-out 0s;
		transition: background-color .2s ease-in-out 0s;
	}

	.menu li.active {
		color: #fff;
	}
.iconSpan{
	margin-right: 10px;
}
	.menu li.active:after {
		content: "";
		width: 4px;
		background: #fff;
		border-radius: 2px;
		position: absolute;
		right: 10px;
		top: 25%;
		bottom: 25%;
	}
</style>
