<template>
	<div>
		<div class="wrapper-main" style="padding:0px">
			<div class="wrapper-cont">
		<!--订单统计-->
		<div class="data-cont">
			<el-row class="dataTitle">订单统计</el-row>
			<el-row :gutter="20">
				<el-col :span="6">
					<!-- <div class="flex">
						<div class="iconCrl mr10" style="background:#00c050">
							<i class="el-icon-bank-card"></i>
						</div> -->
						<div class="grid-content bg-purple items">
							<div class="dataval">{{orderData.unpaidCount}}</div>
							<div>待付款订单</div>
						</div>
					<!-- </div> -->
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple items">
						<div class="dataval">{{orderData.undeliveredCount}}</div>
						<div>待发货订单</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple items">
						<div class="dataval">{{orderData.unreceivedCount}}</div>
						<div>待收货订单</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple items">
						<div class="dataval">{{orderData.noRefundCount}}</div>
						<div>待退款订单</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!--商城统计-->
		<div class="data-cont">
			<el-row class="dataTitle">商城统计</el-row>
			<el-row :gutter="20">
				<el-col :span="6">
					<div class="grid-content bg-purple items">
						<div class="dataval">{{mallData.shopCount}}</div>
						<div>网店总量</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple items">
						<div class="dataval">{{mallData.channelCount}}</div>
						<div>服务商总量</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple items">
						<div class="dataval">{{mallData.orderCount}}</div>
						<div>订单总量</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple items">
						<div class="dataval">{{mallData.allUserCount}}</div>
						<div>用户总量</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!--实时概况-->
		<div class="data-cont">
			<el-row :gutter="20">
				<el-col :span="16">
					<div class="grid-content bg-purple timeStyle">
						<el-row class="dataTitle">实时概况</el-row>
						<el-row :gutter="20" class="mb20">
							<el-col :span="6">
								<div class="flex">
									<div class="iconCrl mr10" style="background:#00c050">
										<i class="el-icon-bank-card"></i>
									</div>
									<div class="grid-content bg-purple timeItems">
										<div>网店销售额(元)</div>
										<div class="datatimeval">{{todaydata.orderSales}}</div>
										<div>昨日：{{yesterdaydata.orderSales}}</div>
									</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="flex">
									<div class="iconCrl mr10" style="background:#b37feb">
										<i class="el-icon-set-up"></i>
									</div>
									<div class="grid-content bg-purple timeItems">
										<div>网店支付订单数</div>
										<div class="datatimeval">{{todaydata.orderNewCount}}</div>
										<div>昨日: {{yesterdaydata.orderNewCount}}</div>
									</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="flex">
									<div class="iconCrl mr10" style="background:#ffab2b">
										<i class="el-icon-money"></i>
									</div>
									<div class="grid-content bg-purple timeItems">
										<div>网店入驻销售额(元)</div>
										<div class="datatimeval">{{todaydata.shopSales}}</div>
										<div>昨日：<span class="sizecolor">{{yesterdaydata.shopSales}}</span></div>
									</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div class="flex">
									<div class="iconCrl mr10" style="background:#b37feb">
										<i class="el-icon-house"></i>
									</div>
									<div class="grid-content bg-purple timeItems">
										<div>新增网店</div>
										<div class="datatimeval">{{todaydata.shopNewCount}}</div>
										<div>昨日：<span class="sizecolor">{{yesterdaydata.shopNewCount}}</span></div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="grid-content bg-purple timeStyle" style="padding-top: 54px;">
						<el-row :gutter="20"  class="mb20">
							<el-col :span="12">
							<div class="flex">
								<div class="iconCrl mr10" style="background:#1890ff">
									<i class="el-icon-coin"></i>
								</div>
								<div class="grid-content bg-purple timeItems">
									<div>服务商销售额(元)</div>
									<div class="datatimeval">{{todaydata.channelSales}}</div>
									<div>昨日：<span class="sizecolor">{{yesterdaydata.channelSales}}</span></div>
								</div>
							</div>
							
							</el-col>
							<el-col :span="12">
								<div class="flex">
									<div class="iconCrl mr10" style="background:#00c050">
										<i class="el-icon-service"></i>
									</div>
									<div class="grid-content bg-purple timeItems">
										<div>新增服务商</div>
										<div class="datatimeval">{{todaydata.channelNewCount}}</div>
										<div>昨日：<span class="sizecolor">{{yesterdaydata.channelNewCount}}</span></div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</div>
		<!--交易走势-->
		<div class="data-cont">
			<el-row class="dataTitle">交易走势</el-row>
			<div style="height: 300px;position: relative;" >
				<el-radio-group v-model="tabPosition" size="small"  @change="changeTrade"  style="position: absolute;top:0px; right: 10%;">
				    <el-radio-button label="week">本周</el-radio-button>
				    <el-radio-button label="month">本月</el-radio-button>
				  </el-radio-group>
				  <el-tabs :tab-position="tabPosition">
					<div id="personDetail" ref="personDetail" style="width: 100%; height: 280px;"></div>
				</el-tabs>
			</div>
			
		</div>
		<!--销售商品榜单-->
		<div class="data-cont" style="margin-bottom:50px">
			<el-row class="dataTitle" style="margin-bottom: 0;">销售商品榜单</el-row>
			<div style="position: relative; height: 40px;">
				<el-radio-group v-model="tabTable" size="small"  @change="changeSales" style="position: absolute;top:0px; right: 10%;">
				    <el-radio-button label="week">本周</el-radio-button>
				    <el-radio-button label="month">本月</el-radio-button>
				  </el-radio-group>
			</div>
			  <el-tabs :tab-position="tabTable">
				<el-table :data="tableData" style="width: 100%" max-height="380" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" stripe border @sort-change="sortChange"  class="table_one">
					<el-table-column type="index" width="80" align="center" label="排名">
					</el-table-column>
					<el-table-column min-width="280" label="商品名称" align="left">
						<template slot-scope="scope">
							<div class="el_names">
								<img :src="scope.row.image.split(',', 1)" class="el_img" />
								<div>{{ scope.row.goodsName }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="cost" label="结算价"  sortable="cost" align="center">
					</el-table-column>
					<el-table-column prop="stock" label="总库存" sortable="stock" align="center">
					 </el-table-column>
					<el-table-column label="实际销量" align="center" prop="salesVolume" sortable="salesVolume">
					</el-table-column>
					<el-table-column label="成交额" align="center" prop="sales"  sortable="sales">
						<template slot-scope="scope">
							<div style="color: #0091ff">{{ scope.row.sales }}</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tabs>
		</div>
		</div>
		</div>
	</div>
</template>

<script>
	import {
		statistics,
		tradeData,
		goodsSaleData
	} from '@/api/plat/platindex'
	export default{
		data(){
			return{
				tabPosition: 'week',
				tabTable:'week',
				field:'',
				sort:'',
				tableData:[
					 {
						 stock: "99.00",
						 image: "https://giftoss.gcaimall.com/f0a3348e74d811ebb06b02424d14ae90.png",
						 cost: "66.00",
						 salesVolume: "1",
						 goodsName: "测试1",
						 sales:'66'
					 }
				],
				orderData:{},//订单统计
				mallData:{},//商城统计
				todaydata:{},//今日数据
				yesterdaydata:{},//昨日数据
				tradeTime:[],//交易量
				tradeCount:[],//交易量
				tradeMoney:[],//交易额
			}
		},
		mounted() {
			//数据
			this.getStatistics();
			//交易走势
			this.getTradeData();
			//商品销售榜单
			this.getGoodsSaleData()
		},
		methods: {
			//交易切换
			changeTrade(val){
				this.tabPosition = val;
				this.getTradeData();
				
			},
			//切换销售榜单
			changeSales(val){
				this.tabTable = val;
				this.getGoodsSaleData();
			},
			//获取数据
			getStatistics(){
				let params={}
				statistics(params).then(res=>{
					this.orderData = res.data.orderData;
					this.mallData = res.data.mallData;
					this.todaydata = res.data.overview.today;
					this.yesterdaydata = res.data.overview.yesterday;
					
				})
			},
			//交易走势
			getTradeData(){
				let params={
					filter:this.tabPosition
				}
				tradeData(params).then(res=>{
					let data=res.data;
					let tradeT=[],tradeC=[],tradeM=[];
					data.forEach(item=>{
						tradeT.push(item.timeStr);
						tradeC.push(item.salesVolume);
						tradeM.push(item.sales);
					});
					
					this.loadLine(tradeT,tradeC,tradeM);
				})
			},
			//销售榜单
			getGoodsSaleData(){
				let params={
					filter:this.tabTable,
					field:this.field,
					sort:this.sort
				};
				params=this.tools.filterParams(params);
				goodsSaleData(params).then(res=>{
					this.tableData = res.data
				})
			},
			
			loadLine(tradeT,tradeC,tradeM) {
			      let myChart = this.$echarts.init(this.$refs.personDetail);
			         let option = {
			           title: {
			             // text: "折线图堆叠",
			           },
			           tooltip: {
			             trigger: "axis",
			           },
					   color:['#5AD8A6','#5B8FF9'], 
			           legend: {
			             data: ["成交额", "成交量"],
						 textStyle: {
							 fontSize: 12,
							 color: '#666'
						 }
			           },
			           grid: {
			             left: "3%",
			             right: "4%",
			             bottom: "3%",
			             containLabel: true,
			           },
			           toolbox: {
			             feature: {
			               saveAsImage: {},
			             },
			           },
			           xAxis: {
			             type: "category",
			             boundaryGap: false,
			             data: tradeT,
			           },
			           yAxis: {
			             type: "value",
			           },
			           series: [
			             {
			               name: "成交额",
			               type: "line",
			               stack: "总量",
			               data: tradeM,
						  itemStyle : {  
								   normal : {  
									   lineStyle:{  
										   color:'#5AD8A6'  
									   }  
								   }  
							   },
			             },
			             {
			               name: "成交量",
			               type: "line",
			               stack: "总量",
			               data: tradeC,
						   itemStyle : {
							   normal : {  
								   lineStyle:{  
									   color:'#5B8FF9'  
								   }  
							   }  
						   },
			             }
			           ],
			         };
			         // 使用刚指定的配置项和数据显示图表。
			         myChart.setOption(option);
			
			    },
				//排序
				sortChange(column){
					//console.log(column,"4444")
					if(column.order == "ascending"){
						this.sort="0";
						this.field=column.prop
						
					}else if(column.order == "descending"){
						this.sort="1";
						this.field=column.prop
					}
					this.getGoodsSaleData()
				}
		
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../assets/css/platdashboard.scss";
	.iconCrl {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    opacity: .7;
    border: 6px solid rgba(255,255,255,.8);
}
.iconCrl i{
	color: #fff;
}
</style>
