<template>
	<div>
		<div ref="hideitem" class="sidebar" v-if="(subMenuItemList && subMenuItemList.length>0 && !subMenuItemList[0].isShow)">
			<ul class="menu">
				<li v-for="(sub,index1) in subMenuItemList" :key='sub.name' :index='sub.name' >
					<div class="caption" v-if="sub.meta.caption && !sub.hidden">{{sub.meta.caption}}</div>
					<div class="mainMenu" v-if="!sub.hidden" @click="toSubMenu(index1,sub.name)" :class="{ 
					active:$route.path.split('/')[2]==sub.path}">
						{{sub.meta.name}}
					</div>
					<div class="hasLine" v-if="sub.meta.line && !sub.hidden"></div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	import bus from './bus';
	export default {
		props: {
			menuList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		watch: {
			'$route.path': {
				handler(val) {
					
					let routeList = this.$store.state.permission.sidebarMenu; //this.menuList;
					if (routeList && routeList.length > 0) {
						for (let i = 0; i < routeList.length; i++) {
							let route = routeList[i];
							// if ('/' + this.$route.path.split('/')[1] == route.path) {
							if ('/' + val.split('/')[1] == route.path) {
								if (route.children) {
									if (route.children.some(item => !item.hidden)) {
										this.$emit("hasMenu", true);
										this.subMenuItemList = JSON.parse(JSON.stringify(route.children));
										this.$forceUpdate();
										break;
									} else {
										this.$emit("hasMenu", false);
									}

								} else {
									this.$emit("hasMenu", false);
								}
							}
						}
					};
				},
				immediate: true,
				deep: true,
			}
		},
		data() {
			return {
				current: 0,
				curUrl: '',
				subMenuItemList: null,
				routeData: [], //缓存路由
			}
		},
		mounted() {
			bus.$on('hiddenitem', msg => {
				if(msg == 'hide'){
					this.subMenuItemList = []
				}
			});
			bus.$on('subMenuItem', msg => {
				let subMenu = msg;
				if (subMenu != null) {
					subMenu.forEach(yy => {
						if (yy.children != null) {
							this.subMenuItemList = subMenu;
						}
					})
				} else if (subMenu == null) {
					this.subMenuItemList = []
				}
			});
		},
		methods: {
			toSubMenu(index, name) {
				this.current = index;
				this.$router.push({
					name
				})
			}
		},
	}
</script>
<style scoped="scoped">
	.sidebar {
		width: 120px;
		padding: 5px;
		display: block;
		/* position: absolute;
		left: 0;
		top: 60px;
		bottom: 0; */
		overflow-y: scroll;
		overflow-x: hidden;
	}
	.sidebar::-webkit-scrollbar {
		display: none;
	}
	.sidebar ul {
		height: 100%;
		/* //overflow: hidden; */
	}
	.menu {
		width: 110px;
		list-style: none;
		position: relative;
		margin: 0;
		padding-left: 0;
		color: #595961
	}
	.menu li {
		cursor: pointer;
	}
	.hasLine {
		width: 95;
		height: 1px;
		background-color: #E9EAEF;
		margin: 10px 5px 10px 10px;
	}
	.menu li .mainMenu {
		position: relative;
		font-size: 12px;
		color: #888;
		padding: 11px 15px;
		font-weight: 400;
		text-transform: none;
		display: block;
		position: relative;
		line-height: 1.42857143;
		-webkit-transition: background-color .2s ease-in-out 0;
		-webkit-transition: background-color .2s ease-in-out 0s;
		transition: background-color .2s ease-in-out 0s;
	}
	.menu li .mainMenu.active {
		color: #333333;
		background: #E9EAEF;
		border-radius: 6px;
	}
	.menu li .mainMenu.active:after {
		content: "";
		width: 0;
		height: 0;
		border-color: transparent transparent transparent #000;
		border-style: solid;
		border-width: 4px 0 4px 6px;
		position: absolute;
		right: 10px;
		top: 16px;
	}
	.caption {
		padding: 11px 18px;
		font-weight: bold;
		font-size: 12px;
	}
</style>
