import { render, staticRenderFns } from "./merchantindex.vue?vue&type=template&id=b8df4c74&scoped=true&"
import script from "./merchantindex.vue?vue&type=script&lang=js&"
export * from "./merchantindex.vue?vue&type=script&lang=js&"
import style0 from "./merchantindex.vue?vue&type=style&index=0&id=b8df4c74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8df4c74",
  null
  
)

export default component.exports