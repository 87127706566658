<template>
	<div class="login" :style="login">
		<el-row :gutter="10" class="login-container">
			<div class="titles">志同道合 合作共赢</div>
			<div class="login_main">
				<el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="1em" class="login-body">
					<div class="logintop">
						<!-- <img src="../../assets/img/tszjlogo.png" />--><span>众康联管理员登陆 </span> 
					</div>
					<div class="login_title">密码登录</div>
					<el-form-item prop="username">
						<el-input v-model.trim="loginForm.username" clearable @input="phoneChange1" maxlength="11"
							placeholder="请输入手机号">
						</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input type="password" maxlength="16" show-password placeholder="请输入登录密码" clearable
							v-model.trim="loginForm.password">
						</el-input>
					</el-form-item>
					<el-form-item style="margin-bottom:10px">
						<el-checkbox v-model="checked" class="myRedCheckBox">记住密码</el-checkbox>
					</el-form-item>
					<el-form-item class="login-body-button">
						<el-button class="login-submit v-main" type="primary"  @click="submitLoginForm('loginForm')">登录</el-button>
					</el-form-item>
					<div class="loginxy" @click="openAgreen">
						登录后表明您同意
						<span>登录服务协议</span>
					</div>
				</el-form>
			</div>
		</el-row>
		<!--弹框-->
		<el-dialog title="众康联用户入驻协议" center :visible.sync="agreementModel" width="40%">
			<div class="agreeCont">
				<Editor v-model="agreenData" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	logins,
	getProtocol,
	getTestAes
} from '@/api/plat/platlogin'
import { routerDispose } from '@/utils/recursion-router'
import Editor from '../common/loginEditor';
const sha256 = require('js-sha256').sha256;
import loginbg from '@/assets/img/shopbg.png'
import { isvalidPhone } from '@/utils/validate'
import permissionJson from '../../../static/plat.json'
var validPhone = (rule, value, callback) => {
	if (!value) {
		callback(new Error('请输入手机号'))
	} else if (!isvalidPhone(value)) {
		callback(new Error('请输入正确的11位手机号码'))
	} else {
		callback()
	}
};
export default {
	components: {
		Editor
	},
	data() {
		return {
			login: {
				height: '',
				backgroundImage: 'url(' + loginbg + ')'
			},
			agreementModel: false,
			show: true,
			checked: true,
			count: '',
			timer: null,
			agreenData: '',
			curIndex: 0,
			titleItem: [{
				id: 1,
				name: '密码登录'
			}, {
				id: 2,
				name: '验证码登录'
			}],
			loginForm: {
				username: '13269822845', //13269822845
				password: '123456', //123456789
			},

			rules: {
				username: [
					{ required: true, trigger: 'blur', validator: validPhone }
				],
				password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}],
			},
		}
	},
	created() {
		this.login.height = document.body.clientHeight + "px"
	},
	mounted() {
		this.$store.commit('LOGIN_OUT');
		localStorage.removeItem('dataset');
		localStorage.removeItem('head_portrait');
		localStorage.removeItem('terminal');
		localStorage.removeItem('shop_name');
		localStorage.removeItem('refreshToken')
		localStorage.removeItem('token');
		if (location.href.indexOf("#reloaded") == -1) {
			location.href = location.href + "#reloaded";
			window.location.reload();
		}
	},
	methods: {
		getAev() {
			let aa = JSON.stringify({
				account: '1213'
			});

			var a = this.aes.encrypt(aa);
			let data = {
				secretData: a
			}
			getTestAes(data).then(res => {

			})
		},
		//读取cookie
		getCookie() {
			if (document.cookie.length > 0) {
				var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
				for (var i = 0; i < arr.length; i++) {
					var arr2 = arr[i].split('='); //再次切割
					//判断查找相对应的值
					if (arr2[0] == 'userName') {
						this.loginForm.username = arr2[1]; //保存到保存数据的地方
					} else if (arr2[0] == 'userPwd') {
						this.loginForm.password = arr2[1];
					}
				}
			}
		},
		openAgreen() {
			this.agreementModel = !this.agreementModel;
			this.getProtocolData()
		},
		getProtocolData() {
			let data = {
				type: 1
			}
			getProtocol(data).then(res => {
				this.agreenData = res.data;
			})
		},
		phoneChange1() {
			this.loginForm.username = this.loginForm.username.replace(/[^\d]/g, "");
			var reg = /^1[3456789]\d{9}$/;
		},
		selected(indx) {
			this.curIndex = indx;
		},
		submitLoginForm(loginForm) {
			this.$refs[loginForm].validate((valid) => {
				if (valid) {
					this.loginsub();
				} else {
					this.$message.error('请输入账号和密码');
					return false;
				}
			});
		},
		async loginsub() {
			var reg = /^1[3456789]\d{9}$/;
			if (this.loginForm.username.length >= 11 && !reg.test(this.loginForm.username)) {
				this.$message.error("请输入正确的手机号码格式");
				return;
			}
			if (this.loginForm.password.length < 6 || this.loginForm.password.length > 16) {
				this.$message.error('请输入6-16位密码');
				return;
			}
			//记住密码
			if (this.checked == true) {
				//传入账号名，密码，和保存天数3个参数
				this.tools.setCookie(this.loginForm.username, this.loginForm.password, 7);
			} else {
				//清空Cookie
				this.tools.clearCookie();
			};
			let params;
			params = {
				account: this.loginForm.username,
				password: sha256(this.loginForm.password)
			}
			try {
				let data = await logins(params);
				localStorage.setItem('dataset', JSON.stringify(routerDispose(permissionJson)));//保存权限路由
				//localStorage.setItem('dataset',JSON.stringify(routerDispose(data.data.permission)));//保存权限路由
				let token = data.data.token;
				localStorage.setItem('dynamic', data.data.dynamic);
				localStorage.setItem('refreshToken', data.data.refreshToken);
				localStorage.setItem('terminal', data.data.terminal);
				localStorage.setItem('head_portrait', data.data.head_portrait);
				localStorage.setItem('shop_name', data.data.shop_name);
				this.$store.commit('LOGIN_IN', token);
				this.$router.replace('/home')
			} catch (e) {
				this.$message.error(e.return_msg)
			}


		}
	},
};
</script>

<style lang="scss" scoped>
 @import "@/assets/css/login.scss";
.logintop {
	height: 44px;
	line-height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		vertical-align: middle;

	}

	span {
		margin-left: 5px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2589FF;
		letter-spacing: 2px;
		line-height: 44px;
	}
}

.titles {
	height: 59px;
	font-size: 26px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #F6FBFF;
	text-align: center;
	letter-spacing: 2px;
	line-height: 59px;
	margin-bottom: 20px;
}

.v-main {
	background: #2589FF;
	border-radius: 3px;
}

.login {
	background-size: cover;

	.login_title {
		font-size: 18px;
		color: #333;
		line-height: 28px;
		text-align: left;
		padding: .9rem .625rem;
	}

	.login-logo {
		width: 120px;
		height: 120px;
		display: block;
		margin: 2rem auto;
	}

	.loginheader {
		position: absolute;
		left: 64%;
		top: 25%;
		transform: translate(-50%, -50%);
	}

	:deep(.el-image__inner) {
		width: 65%;
		vertical-align: top;
	}

	.login-container {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		// padding: 0.8rem;
	}

	.login_main {
		background: #ffffff;
		border-radius: 14px;
	}

	.login-body {
		padding: 1rem 3rem 5rem 3rem;
		width: 27rem;
		border-radius: 0.2rem;
		position: relative;
	}

	.login_img {
		width: 127px;
		height: 143px;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: -1;
	}

	.el-input__inner {
		height: 40px;
		line-height: 40px;
		box-shadow: none;
		border: none;
		border-bottom: 1px solid #e2e2e2;
		border-radius: 0;
		padding-left: 1em;
	}

	// .el-input__inner {
	//     border-radius: 20px;
	// }

	.codeImg {
		height: 40px;
	}

	.login-submit {
		width: 100%;
	}

	.loginxy {
		cursor: pointer;
		position: absolute;
		width: 80%;
		bottom: 3rem;
		text-align: center;
		font-size: 12px;
		color: #333;

		span {
			color: #e02020;
		}
	}

	.w-e-toolbar {
		display: none;
		border-top: 1px solid #c9d8db;
	}

	.login-body-button {
		.el-button {
			height: 45px;
			// line-height: 45px;
		}
	}


	/* 设置带边框的checkbox，选中后边框的颜色 */

	/* 设置选中后的文字颜色 */

	/* 设置选中后对勾框的边框和背景颜色 */
	.myRedCheckBox :deep(.el-checkbox__input.is-checked .el-checkbox__inner),
	.myRedCheckBox :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner) {
		border-color: #405fe5;
		background-color: #405fe5;
	}

	/* 设置checkbox获得焦点后，对勾框的边框颜色 */
	.myRedCheckBox :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
		border-color: #405fe5;
	}

	/* 设置鼠标经过对勾框，对勾框边框的颜色 */
	.myRedCheckBox :deep(.el-checkbox__inner:hover) {
		border-color: #405fe5;
	}

	::v-deep.el-button.el-button--primary {
		background: #2589FF !important;
		border-color: #2589FF;
	}

	:deep(.w-e-toolbar) {
		display: none;
	}

	::v-deep.w-e-text-container {
		border-top: 1px solid #c9d8db !important;
	}
}
::v-deep.ql-container.ql-snow {
	border: none;
	padding: 0;
}

::v-deep.ql-toolbar.ql-snow {
	border: none;
	padding: 0;
}

.agreeCont {
	width: 100%;
	min-height: 27.5rem;
	overflow-y: scroll;
}
::v-deep.ql-editor {
	overflow-y: scroll;
}

::v-deep.ql-editor::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

::v-deep.ql-editor {
	-ms-overflow-style: none;
}

::v-deep.ql-editor {
	overflow: -moz-scrollbars-none;
}

::v-deep.ql-editor::-webkit-scrollbar {
	width: 0 !important
}

::v-deep.ql-editor {
	-ms-overflow-style: none;
}

::v-deep.ql-editor {
	overflow: -moz-scrollbars-none;
}
</style>

