<template>
	<div class="login-wrap">
		<div class="logopic">
			<span style="color:#2589FF">众康联</span>
			<!-- <img src="../../assets/img/tszjlogo.png" class="logoimg" /> -->
			<span> | 众合一美管理系统</span>
		</div>
		<div class="login_counts">
			<div class="loginbox">
				<div class="loginmain">
					<div class="item-title ">
						<li v-for="(item,index) in titleItem" :key="item.name" :class="{active : curIndex == index}"
							@click="curIndex = index">{{item.name}}</li>
					</div>
					<el-form v-if="curIndex==0" :model="loginMerchantForm" :rules="merchantrules"
						ref="loginMerchantForm" label-width="0px" class="ms-content">
						<el-form-item prop="username">
							<el-input v-model="loginMerchantForm.username" style="padding-left:0px;" clearable
								@input="phoneChange1" maxlength="11" placeholder="请输入手机号">
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input type="password" maxlength="16" placeholder="请输入登录密码" clearable
								v-model="loginMerchantForm.password">
							</el-input>
						</el-form-item>
						<div>
							<el-checkbox v-model="checked">记住密码</el-checkbox>
						</div>
						<div class="login-btn">
							<el-button type="primary" @click="submitMerchantLoginForm('loginMerchantForm')">登录
							</el-button>
						</div>
						<div class="loginxy" @click="openAgreen">登录后表明您同意<span>登录服务协议</span></div>
					</el-form>
					<el-form v-if="curIndex==1" :model="codeMerchantForm" :rules="codemerchantrules"
						ref="codeMerchantForm" label-width="0px" class="ms-content">
						<el-form-item prop="phone">
							<el-input @input="phoneChange2" maxlength="11" clearable v-model="codeMerchantForm.phone"
								placeholder="请输入手机号">
							</el-input>
						</el-form-item>
						<el-form-item prop="code">
							<div class="codeitem">
								<el-input class="inputcode" type="password" maxlength="6" placeholder="请输入验证码"
									v-model="codeMerchantForm.code">
								</el-input>
								<div class="codebtn" v-show="show" @click="getMerchantCode">获取验证码</div>
								<div v-show="!show" class="codebtn">{{count}} s</div>
							</div>
						</el-form-item>

						<div class="login-btn">
							<el-button type="primary" @click="submitMerchantCodeForm('codeMerchantForm')">登录</el-button>
						</div>
					</el-form>
				</div>
			</div>
			<div class="loginpic">
				<img src="../../assets/img/loginactive.png" />
				<div class="maintitles">更专业的门店管理系统</div>
				<!-- <div class="subtitles">路人变新客，新客变老客，老客带新客</div> -->
			</div>
		</div>
		<el-dialog title="众康联用户入驻协议" center :visible.sync="agreementModel" width="40%">
			<div class="agreeCont">
				<Editor v-model="agreenData" />
			</div>
		</el-dialog>
	</div>
</template>
<script>
import {
	shopAccountLogin,
	loginSmsCodeSend,
} from '@/api/merchant/login'
import {
	getProtocol
} from '@/api/plat/platlogin'
import permissionJson from '../../../static/shop.json'
import Editor from '../common/loginEditor';
const sha256 = require('js-sha256').sha256;
import { isvalidPhone } from '@/utils/validate'

var validPhone = (rule, value, callback) => {
	if (!value) {
		callback(new Error('请输入手机号'))
	} else if (!isvalidPhone(value)) {
		callback(new Error('请输入正确的11位手机号码'))
	} else {
		callback()
	}
};
export default {
	components: {
		Editor
	},
	data() {
		return {
			agreementModel: false,
			agreenData: '',
			titleItem: [{
				id: 1,
				name: '密码登录'
			}, {
				id: 2,
				name: '验证码登录'
			}],
			checked: true,
			show: true,
			curIndex: 0,
			timer: '',
			count: '',
			//商户登录
			loginMerchantForm: {
				username: '', //17682310521
				password: '', //123456
			},
			codeMerchantForm: {
				phone: '',
				code: '',
			},
			codemerchantrules: {
				phone: [
					{ required: true, trigger: 'blur', validator: validPhone }
				],
				code: [{
					required: true,
					message: '请输入验证码',
					trigger: 'blur'
				}],
			},
			merchantrules: {
				username: [
					{ required: true, trigger: 'blur', validator: validPhone }
				],
				password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}],
			},
		}
	},
	methods: {
		openAgreen() {
			this.agreementModel = !this.agreementModel;
			this.getProtocolData()
		},
		getProtocolData() {
			let data = {
				type: 1
			}
			getProtocol(data).then(res => {
				this.agreenData = res.data;
			})
		},
		//验证手机号
		phoneChange1() {
			this.loginMerchantForm.username = this.loginMerchantForm.username.replace(/[^\d]/g, "");
			var reg = /^1[3456789]\d{9}$/;

		},
		submitMerchantLoginForm(loginMerchantForm) {
			this.$refs[loginMerchantForm].validate((valid) => {
				if (valid) {
					var reg = /^1[3456789]\d{9}$/;
					if (this.loginMerchantForm.username.length >= 11 && !reg.test(this.loginMerchantForm.username)) {
						this.$message.error("请输入正确的手机号码格式");
						return;
					}
					if (this.loginMerchantForm.password.length < 6 || this.loginMerchantForm.password.length > 16) {
						this.$message.error('请输入6-16位密码');
						return;
					}
					//记住密码
					if (this.checked == true) {
						//传入账号名，密码，和保存天数3个参数
						this.tools.setCookie(this.loginMerchantForm.username, this.loginMerchantForm.password, 7);
					} else {
						//清空Cookie
						this.tools.clearCookie();
					}
					//调接口
					let params = {
						account: this.loginMerchantForm.username,
						password: sha256(this.loginMerchantForm.password),
						terminal:'shopPlatform'
					}
					// let rasparams={
					// 	secretData:this.encryption.$getrsa(JSON.stringify(params))
					// } 
					console.log('在外面');
					
					shopAccountLogin(params).then(res => {
						console.log('没进去');
						
						if (res.data.terminal == 'shop') {

							console.log('进去了');
							
							console.log(permissionJson,"permissionJson")
							let token = res.data.token;
							localStorage.setItem('dynamic', res.data.dynamic);
							localStorage.setItem('refreshToken', res.data.refreshToken);
							localStorage.setItem('terminal', res.data.terminal);
							this.$store.commit('LOGIN_IN', token);
							localStorage.setItem('head_portrait', res.data.head_portrait);
							localStorage.setItem('shop_name', res.data.shop_name);
							localStorage.setItem('account_id', res.data.account_id);

							// localStorage.setItem('dataset', JSON.stringify(permissionJson));//保存权限路由
							localStorage.setItem('dataset',JSON.stringify(res.data.permission));//保存权限路由
							// localStorage.setItem('dataset', res.data.permission);//保存权限路由
							this.$router.replace('/home').catch(err => { })
						} else {
							this.$message.error("账号不存在！");
						}
					}).catch(err => {
						this.$message.error(err.return_msg)
					})
				} else {
					this.$message.error('请输入账号和密码');
					return false;
				}
			});
		},
		//读取cookie
		getCookie() {
			if (document.cookie.length > 0) {
				var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
				for (var i = 0; i < arr.length; i++) {
					var arr2 = arr[i].split('='); //再次切割
					//判断查找相对应的值
					if (arr2[0] == 'userName') {
						this.loginMerchantForm.username = arr2[1]; //保存到保存数据的地方
					} else if (arr2[0] == 'userPwd') {
						this.loginMerchantForm.password = arr2[1];
					}
				}
			}
		},
		//发送验证码
		phoneChange2() {
			this.codeMerchantForm.phone = this.codeMerchantForm.phone.replace(/[^\d]/g, "");
		},
		getMerchantCode() {
			if (this.codeMerchantForm.phone == '') {
				this.$message.error("请输入手机号！");
				return;
			}
			let data = {
				account: this.codeMerchantForm.phone,
				random: 'shopAccountLogin',
				
			}
			// let rasdata={
			// 	secretData:this.encryption.$getrsa(JSON.stringify(data))
			// } 
			loginSmsCodeSend(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					const TIME_COUNT = 60;
					if (!this.timer) {
						this.count = TIME_COUNT;
						this.show = false;
						this.timer = setInterval(() => {
							if (this.count > 0 && this.count <= TIME_COUNT) {
								this.count--;
							} else {
								this.show = true;
								clearInterval(this.timer);
								this.timer = null;
							}
						}, 1000)
					}
				}
			}).catch(err => {
				this.$message.error(err.return_msg)
			})
		},
		submitMerchantCodeForm(codeMerchantForm) {
			this.$refs[codeMerchantForm].validate((valid) => {
				if (valid) {
					var reg = /^1[3456789]\d{9}$/;
					if (this.codeMerchantForm.phone.length >= 11 && !reg.test(this.codeMerchantForm.phone)) {
						this.$message.error("请输入正确的手机号码格式");
						return;
					}
					//调接口
					let params = {
						account: this.codeMerchantForm.phone,
						sms_code: this.codeMerchantForm.code,
						terminal:'shopPlatform'
					}
					// let rasparams={
					// 	secretData:this.encryption.$getrsa(JSON.stringify(params))
					// } 
					shopAccountLogin(params).then(res => {
						if (res.data.terminal == 'shop') {
							// console.log(permissionJson,"permissionJson")
							let token = res.data.token;
							localStorage.setItem('dynamic', res.data.dynamic);
							localStorage.setItem('refreshToken', res.data.refreshToken);
							localStorage.setItem('terminal', res.data.terminal);
							localStorage.setItem('head_portrait', res.data.head_portrait);
							localStorage.setItem('shop_name', res.data.shop_name);
							this.$store.commit('LOGIN_IN', token);

							// localStorage.setItem('dataset', JSON.stringify(permissionJson));//保存权限路由
							localStorage.setItem('dataset',JSON.stringify(res.data.permission));//保存权限路由
							//localStorage.setItem('dataset', res.data.permission);//保存权限路由
							this.$router.replace('/home').catch(err => { })
						} else {
							this.$message.error("账号不存在！");
						}

					}).catch(err => {
						this.$message.error(err.return_msg)
					})
				} else {
					this.$message.error('请输入账号和验证码');
					return false;
				}
			});
		},
	}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/login.scss";

.titles {
	margin-top: 35px;
}

//切换菜单
.item-title {
	// width: 100%;
	display: flex;
	margin-bottom: 30px;
}

.item-title li {
	list-style: none;
	width: 100px;
	line-height: 34px;
	height: 34px;
	color: #999;
	font-size: 16px;
	cursor: pointer;
	text-align: center;
	margin-right: 30px
}

.item-title li.active {
	width: 100px;
	height: 34px;
	line-height: 34px;
	border-radius: 4px;
	color: #333300;
	font-size: 20px;

}


::v-deep.ql-container.ql-snow {
	border: none;
	padding: 0;
}

::v-deep.ql-toolbar.ql-snow {
	border: none;
	padding: 0;
}

.agreeCont {
	width: 100%;
	min-height: 27.5rem;
	overflow-y: scroll;
}

::v-deep.ql-editor {
	overflow-y: scroll;
}

::v-deep.ql-editor::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

::v-deep.ql-editor {
	-ms-overflow-style: none;
}

::v-deep.ql-editor {
	overflow: -moz-scrollbars-none;
}

::v-deep.ql-editor::-webkit-scrollbar {
	width: 0 !important
}

::v-deep.ql-editor {
	-ms-overflow-style: none;
}

::v-deep.ql-editor {
	overflow: -moz-scrollbars-none;
}
</style>