<template>
	<div>
		<el-row class="warp" >
			<el-col :span="24" class="warp-breadcrum">
				<!---数据-->
				<el-row :gutter="20" style="margin-bottom:10px; margin-left:0;margin-right:0;background-color: #fff;padding: 10px 0;">
					<el-col :span="3">
						<div class="grid-content bg-purple items">
							<div class="title">服务商名称</div>
							<div class="data">{{data.username}}</div>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="grid-content bg-purple items">
							<div class="title">服务商等级</div>
							<div class="data">{{data.level}}</div>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="grid-content bg-purple items">
							<div class="title">服务商区域</div>
							<div class="data">{{data.area}}</div>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="grid-content bg-purple items">
							<div class="title">账户总收益(元)</div>
							<div class="data">{{data.totalProfit}}</div>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="grid-content bg-purple items">
							<div class="title">账户余额</div>
							<div class="data">{{data.balance}}</div>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="grid-content bg-purple items">
							<div class="title">积分</div>
							<div class="data">{{data.integration}}</div>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="grid-content bg-purple items">
							<div class="title">过期时间</div>
							<div class="data">{{data.expiredTime}}</div>
						</div>
					</el-col>
				</el-row>
				<!--图表-->
				<el-row :gutter="20" class="row-bg" justify="space-around">
					<el-col :span="16">
						<div class="grid-content bg-purple chartBar">
							<div class="mainCont">
								<div class="title"><span></span>商机孵化</div>
								<div id="chartMap" style="height:41.25rem;width:100%;"></div>
							</div>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="grid-content bg-purple chartBar">
							<div class="mainCont">
								<div style="overflow: hidden;">
									<div class="title" style="float: left;"><span></span>店铺入驻数量</div>
									<div style="float: right;">{{valueArry}}份</div>
								</div>
								<div id="chartBar" style="width:100%; height: 20rem;"></div>
							</div>
						</div>
						<div class="grid-content bg-purple chartBar" style="margin-top:1.25rem">
							<div class="mainCont">
								<div class="title"><span></span>营销插件</div>
								<div id="chartPie" style="width:100%; height: 16rem"></div>
							</div>
						</div>
					</el-col>
				</el-row>
				<!--折线图-->
				<el-row class="mt20">
					<el-col :span="24">
						<div class="grid-content bg-purple-dark">
							<div class="mainCont">
								<div class="title"><span></span>近30天收益</div>
								<div id="chartLine" class="charts" style="width:100%; height: 14rem;margin-top:1.25rem">
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import axios from 'axios'
	import echarts from 'echarts'
	import '../../../node_modules/echarts/map/js/china.js'
	import {
		statistics,
	} from '@/api/channel/channelIndex'
	export default {
		data() {
			return {
				chartBar: null,
				chartMap: null,
				chartPie: null,
				chartLine: null,
				valueArry: '',
				data: {}
			};
		},
		mounted() {
			// 柱状图
			this.chartBar = echarts.init(document.getElementById('chartBar'));
			// 折线图
			this.chartLine = echarts.init(document.getElementById('chartLine'));
			// 饼图
			this.chartPie = echarts.init(document.getElementById('chartPie'));
			// 地图
			this.chartMap = echarts.init(document.getElementById('chartMap'));
			this.getStatistics();
		},
		methods: {
			//获取数据
			getStatistics() {
				let params = {}
				statistics(params).then(res => {
					this.data = res.data
					// 柱状图
					this.setChartBar(res.data.shopEntry)
					// 折线图
					this.setChartLine(res.data.profit)
					// 饼图
					this.setChartPie(res.data.pluginList)
					// 地图
					axios.get(`/citys/${this.data.area_code}.json`).then(res => {
						echarts.registerMap(this.data.area_type, res.data);
						this.setChartMap(this.data.shopList)
					})
				})
			},
			// 柱状图数据
			setChartBar(data) {
				let keyArr = [];
				let valueArr = [];
				data.forEach(item => {
					keyArr.push(item.key);
					valueArr.push(item.value);
				});
				this.valueArry = keyArr[keyArr.length - 1];
				this.chartBar.setOption({
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					// dataZoom: [ //滚动条
					//   {
					//     show: true,
					//     realtime: true,
					//     start: 0,
					//     end: 70
					//   },
					//   {
					//     type: 'inside',
					//     realtime: true,
					//     start: 0,
					//     end: 70
					//   }
					// ],
					color: ['#5B8FF9'],
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},

					xAxis: [{
						type: 'category',
						data: keyArr,
						axisTick: {
							alignWithLabel: true
						}
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
						name: '直接访问',
						type: 'bar',
						barWidth: '60%',
						data: valueArr,
						itemStyle: {
							normal: {
								label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 12
									}
								}
							},
						}
					}]
				});
			},
			// 折线图数据
			setChartLine(data) {
				this.chartLine.clear();
				let year = new Date().getFullYear();
				let month = new Date().getMonth() + 1;
				let keyArr = [];
				let valueArr = [];
				data.forEach(item => {
					keyArr.push(item.key);
					valueArr.push(item.value);
				});
				this.chartLine.setOption({
					tooltip: {
						trigger: 'axis',
						formatter: year + '-' + (month > 10 ? month : '0' + month) + '-{b}<br>收益 {c} 元'
					},
					grid: {
						left: '3%',
						right: '3%',
						top: '8%',
						bottom: '5%',
						containLabel: true
					},
					color: ['#a4d8cc', '#25f3e6'],
					calculable: true,
					xAxis: [{
						type: 'category',
						axisTick: {
							show: false
						},
						boundaryGap: false,
						data: keyArr
					}],
					yAxis: {
						type: 'value',
						axisLabel: {
							textStyle: {
								color: '#ccc',
								fontSize: '12',
							}
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(160,160,160,0.3)',
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(160,160,160,0.3)',
							}
						},

					},
					series: [{
						// name:'简易程序案件数',
						type: 'line',
						areaStyle: {

							normal: {
								type: 'default',
								color: new echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
									offset: 0,
									color: '#25f3e6'
								}, {
									offset: 1,
									color: '#0089ff'
								}], false)
							}
						},
						smooth: true,
						itemStyle: {
							normal: {
								areaStyle: {
									type: 'default'
								}
							}
						},
						data: valueArr
					}]
				});
			},
			// 饼图数据
			setChartPie(data) {
				this.chartPie.clear();
				let lineData = [];
				let keyArr = [];
				data.forEach(item => {
					lineData.push({
						name: item.title,
						value: item.profit
					});
					keyArr.push(item.title);
				});
				this.chartPie.setOption({
					backgroundColor: 'rgba(0,0,0,0)',
					tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b}: {c} ({d}%)"
					},
					color: ['#4ac7f5', '#0089ff', '#f36f8a', '#f5c847'],
					legend: { //图例组件，颜色和名字
						x: '70%',
						y: 'center',
						orient: 'vertical',
						itemGap: 12, //图例每项之间的间隔
						itemWidth: 10,
						itemHeight: 10,
						icon: 'rect',
						// data: ['社区接龙', '秒杀', '拼团', '模板装修'],
						data: keyArr,
						textStyle: {
							color: [],
							fontStyle: 'normal',
							fontFamily: '微软雅黑',
							fontSize: 12,
						}
					},
					series: [{
						name: '行业占比',
						type: 'pie',
						clockwise: false, //饼图的扇区是否是顺时针排布
						minAngle: 20, //最小的扇区角度（0 ~ 360）
						center: ['35%', '50%'], //饼图的中心（圆心）坐标
						radius: [60, 85], //饼图的半径
						avoidLabelOverlap: true, ////是否启用防止标签重叠
						// itemStyle: { //图形样式
						//     normal: {
						//         borderColor: '#1e2239',
						//         borderWidth: 2,
						//     },
						// },
						label: { //标签的位置
							normal: {
								show: false,
								// position: 'inside', //标签的位置
								// formatter: "{d}%",
								// textStyle: {
								//     color: '#fff',
								// }
							},
							emphasis: {
								show: false,
								textStyle: {
									fontWeight: 'bold'
								}
							}
						},
						data: lineData
					}, {
						name: '',
						type: 'pie',
						clockwise: false,
						silent: true,
						minAngle: 20, //最小的扇区角度（0 ~ 360）
						center: ['35%', '50%'], //饼图的中心（圆心）坐标
						radius: [0, 40], //饼图的半径
						itemStyle: { //图形样式
							normal: {
								show: false,
								// borderColor: '#1e2239',
								// borderWidth: 1.5,
								// opacity: 0.21,
							}
						},
						label: { //标签的位置
							normal: {
								show: false,
							}
						},
						data: lineData
					}]
				});
			},
			// 地图
			setChartMap(data) {
				let mapData = [];
				data.forEach(item => {
					mapData.push({
						name: item.address,
						value: [item.longitude, item.latitude, item.shopNum, item.turnover]
					});
				});
				this.chartMap.setOption({
					tooltip: {
						trigger: 'item',
						formatter: (params => {
							return params.name + "<br/>" + "店铺量 " + params.value[2] + " 家" + "<br/>" +
								"成交额 " +
								params.value[3] + " 元"
						})
					},
					geo: {
						map: 'city',
						zoom: 1.2,
						roam: true,
						label: {
							show: true
						},
						normal: { //未选中状态
							borderWidth: 1, //边框大小
							areaColor: '#F2F2F6', //大背景
							borderColor: '#ccc', //边框
							label: {
								show: true, //显示名称
								textStyle: {
									color: '#fff' //文字颜色
								}
							}
						},
						emphasis: { // 也是选中样式
							borderWidth: 1,
							areaColor: '#ccc',
							label: {
								show: true,
								textStyle: {
									color: '#333'
								}
							}
						}
					},
					series: [{ // 涟漪效果
						name: 'Top 6',
						type: 'effectScatter',
						coordinateSystem: 'geo',
						data: mapData,
						symbolSize: function(val) {
							return val[2] > 100 ? val[2] / 10 : 10;
						},
						encode: {
							value: 2
						},
						showEffectOn: 'render',
						rippleEffect: {
							brushType: 'stroke',
							color: '#4880FF'
						},
						emphasis: {
							scale: false
						},
						label: {
							formatter: '{b}',
							position: 'right',
							show: false
						},
						itemStyle: {
							shadowBlur: 10,
							shadowColor: '#4880FF',
							color: '#4880FF',
						},
						zlevel: 1
					}]
				})
			}
		},
	}
</script>
<style lang="scss" scoped="scoped">
	.itemList {
		width: 100%;
	}

	// .items {
	// 	display: flex;
	// 	align-items: center;
	// 	flex-wrap: wrap;
	// }

	.items {
		padding: 20px 0;
		border-radius: 4px;
		min-height: 36px;
		background: #FFFFFF;


		.title {
			line-height: 1.25rem;
			font-size: 12px;
			color: #333;
		}

		.data {
			line-height: 1.25rem;
			font-size: 14px;
			color: #333;
			margin-top: 6px
		}
	}

	.warp {
		padding: 12px 20px;
	}

	.warp-breadcrum {
		color: #fff;
		text-align: center;
	}

	.chartBar {
		color: #333;
	}

	.mainCont {
		padding: 20px 20px;
		background: #fff;

		.title {
			height: 1.375rem;
			text-align: left;
			font-size: 16px;
			color: #333;

			span {
				display: inline-block;
				width: 2px;
				height: 9px;
				margin-right: 5px;
				background: #0046F0;
			}
		}
	}
</style>
